import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { cloneDeep } from 'lodash';
import { Router } from '@angular/router';

@Component({
  selector: 'app-pdf-convenio',
  templateUrl: './pdf-convenio.component.html',
  styleUrls: ['./pdf-convenio.component.css']
})
export class PdfConvenioComponent implements OnInit {
  link: string;
  zoom:any=100;
  pdfZoom:any = 1;
  docs:any={};
  regresar:string;
  //public src;
   //src = "https://vadimdez.github.io/ng2-pdf-viewer/assets/pdf-test.pdf";
  src="";
    constructor(private router:ActivatedRoute,
      private routerNav: Router,) { }
  
    onSelect():void{
      this.routerNav.navigate([this.regresar], { queryParams: { } });
      //this.router.navigate(['pdf'], { queryParams:[doc]});
    }

    
    ngOnInit() {
   let footer= this.router.snapshot.queryParams["convenio"];
   this.regresar=footer;
    //  let nombre=this.router.snapshot.queryParams["nombreDoc"];
 /*   if (footer=="footer") {
      this.regresar='';
    }*/
      //this.docs=this.router.snapshot.queryParams;
      console.log(footer);
      let URL_convenio="https://politicasvintedocuments.blob.core.windows.net/conveniodeconfidencialidad/CONVENIO%20CONFIDENCIALIDAD.pdf?sp=r&st=2019-10-07T16:45:27Z&se=2030-10-08T00:45:27Z&spr=https&sv=2018-03-28&sig=n88IG243VeUxnLQO3K3JbcHm8TvhsfmQ1Uix9m9%2Fd5M%3D&sr=b"
    //  this.link=link;
     // console.log("aqui");
     // console.log(this.link);
      this.src= cloneDeep(URL_convenio);
      //console.log(this.src);
      
      
    }
  
    removeZoom(){
      var zoom=
      this.pdfZoom -=.1;
      this.zoom= (this.pdfZoom*100).toFixed(0);
  if (this.pdfZoom<=.5){
    this.pdfZoom=0.5;
    this.zoom=(this.pdfZoom*100).toFixed(0);
  }
  
     // this.pdfZoom=this.pdfZoom.toFixed(2);
      console.log(this.pdfZoom.toFixed(2))
    }
  
  
    addZoom(){
      this.pdfZoom +=.1;
      this.zoom= (this.pdfZoom*100).toFixed(0);
      //this.pdfZoom=this.pdfZoom.toFixed(2);
      if (this.pdfZoom>=2.0){
        this.pdfZoom=2.0;
        this.zoom=(this.pdfZoom*100).toFixed(0);
      }
      console.log(this.pdfZoom.toFixed(2))
    }
  
  
  }
  