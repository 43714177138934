import { Component, OnInit } from '@angular/core';
import { VinteService } from '../vinteServices/vinte.service'
import {GroupsService} from '../area-vinte/service/group.service';
import { ActivatedRoute, Router } from '@angular/router';

import {
  TemplateRef,
  ViewEncapsulation
} from '@angular/core';

import { cloneDeep } from 'lodash';
import { EmptyStateConfig } from 'patternfly-ng/empty-state/empty-state-config';
import { ListConfig } from 'patternfly-ng/list';
import { ActionConfig } from 'patternfly-ng/action';
import { id } from '@swimlane/ngx-datatable/release/utils';
import { createPipeInstance } from '@angular/core/src/view/provider';
import { NotificationService } from '../notification/notification-service/notification.service';
import { NotificationType } from '../notification/notification-type';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-area-politica',
  templateUrl: './area-politica.component.html',
  styleUrls: ['./area-politica.component.css']
})
export class AreaPoliticaComponent implements OnInit {

  allItems: any[];
  selectedModule: any = null;
  actionsText: string = '';
 // allItems: any[];
  listItemsTmp : Array<any> = [];
  emptyStateConfig: EmptyStateConfig;
  items: any[];
  politicasDisponibles: any[];
  itemsAvailable: boolean = true;
  listConfig: ListConfig;
  selectType: string = 'checkbox';
  notifications: any;
  activateEdit:boolean;
  areaEditing:string;

  constructor(
    private groupsService: GroupsService,
    private vinteService: VinteService,
    private notificationService: NotificationService,
    private router: ActivatedRoute) { }

  
  guardarAreasPoliticasRelacion(){
     
    console.log("guardarAreasPoliticasRelacion");    
    var postObj = this.createPostObj();
    console.log(postObj);
    this.vinteService.saveAreaPolicies(postObj)
      .subscribe((politicas: any)=>{  
        this.notificationService.message(
          NotificationType.SUCCESS,
          "Se actualizó la relación de politicas de forma correcta.",
          '',
          null,
          null,
          null);
        },error=>{
          console.log(error);
          
          this.notificationService.message(
            NotificationType.DANGER,
            "tenemos algo de dificultades,  Error:"+error.statusText+error.status,
            '',
            null,
            null,
            null);
          
        });
          

  }

  obtainAreaAndValidateData(){
    this.areaEditing = this.router.snapshot.queryParams["idArea"];
    this.activateEdit = this.areaEditing != '';
    if(this.activateEdit){
      this.selectedModule = this.areaEditing;
    }
    console.log("OBTIENE DE AT");
    
    console.log(this.areaEditing);
    
  }

  createPostObj(){
    console.log(this.selectedModule)
    return {
      areaId: this.selectedModule,
      politicas: this.items
    };
  }


  obtieneTodasLasPoliticasDisponibles(){
    this.vinteService.getPoliticas(null)
      .subscribe(politicas => {
        console.log("Politicas dispobible");
        console.log(politicas);
        this.politicasDisponibles = cloneDeep(politicas);
        //this.changeGroup();
      })
  }

  ngOnInit() {
    
    this.notifications = this.notificationService.getNotifications();
    this.obtainAreaAndValidateData();

    this.groupsService.getGroups().subscribe(grupos => {      
      this.allItems = grupos;      
      this.initConfigTable();      
    });

    if(this.activateEdit){
      this.loadDataFromSelectedAndAllPolicies();
    }          
    
  }
  

  initConfigTable():void{
    this.emptyStateConfig = {
      actions: {
        primaryActions: []
      } as ActionConfig,
      iconStyleClass: 'pficon-warning-triangle-o',
      title: 'No Existen Políticas creadas',
      info: 'Para acceder a la creación de una nueva política favor de hacer click en el boton "nueva política"'
    } as EmptyStateConfig;
  
    this.listConfig = {
      dblClick: false,
      emptyStateConfig: this.emptyStateConfig,
      multiSelect: false,
      selectItems: false,
      selectionMatchProp: 'name',
      showCheckbox: false,
      showRadioButton: false,
      useExpandItems: false
    } as ListConfig;
  }


  
  ngDoCheck(): void {
  }
  updateSelectionType(): void {
    if (this.selectType === 'checkbox') {
      this.listConfig.selectItems = false;
      this.listConfig.showCheckbox = true;
      this.listConfig.showRadioButton = false;
    } else if (this.selectType === 'radio') {
      this.listConfig.selectItems = false;
      this.listConfig.showCheckbox = false;
      this.listConfig.showRadioButton = true;
    } else if (this.selectType === 'row') {
      this.listConfig.selectItems = true;
      this.listConfig.showCheckbox = false;
      this.listConfig.showRadioButton = false;
    } else {
      this.listConfig.selectItems = false;
      this.listConfig.showCheckbox = false;
      this.listConfig.showRadioButton = false;
    }
  }

  handleClick(evt){    
    this.listItemsTmp.push(evt.item);
    this.items = cloneDeep(this.listItemsTmp);          
    const index = this.politicasDisponibles.findIndex(x=>x._id == evt.item._id);  
    this.politicasDisponibles.splice(index, 1);
    this.politicasDisponibles = cloneDeep(this.politicasDisponibles);                  
  }

  handleClickSelected(evt){

    console.log('handleClickSelected');
    this.politicasDisponibles.push(evt.item);
    this.politicasDisponibles = cloneDeep(this.politicasDisponibles);                  

    const index = this.items.findIndex(x=>x._id == evt.item._id);  
    const indexTmp = this.listItemsTmp.findIndex(x=>x._id == evt.item._id);  
    
    this.items.splice(index, 1);
    this.listItemsTmp.splice(indexTmp, 1);

    this.items = cloneDeep(this.items);                  

  }

  handleSelectionChange(evt){
    console.log("Seleccionado");
    
  }

  changeGroup(val){
      this.loadDataFromSelectedAndAllPolicies();
  }

  loadDataFromSelectedAndAllPolicies(){
    this.items = cloneDeep([]);
    this.politicasDisponibles = cloneDeep([]);
    this.listItemsTmp = new Array<any>();
    this.obtieneTodasLasPoliticasDisponibles();    

    this.vinteService.getAreaByGroup(this.selectedModule)
    .subscribe((politicas: any)=>{  
      this.listItemsTmp = politicas[0].politicas;
       this.items = cloneDeep(this.listItemsTmp);                     
      },error=>{
        alert("tenemos algo de dificultades,  Error:"+error.statusText+error.status);
        
      });
  }

}
