import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nueva-area',
  templateUrl: './nueva-area.component.html',
  styleUrls: ['./nueva-area.component.css']
})
export class NuevaAreaComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
