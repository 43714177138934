import {
  Component,
  OnInit,
  TemplateRef,
  ViewEncapsulation
} from '@angular/core';
import { cloneDeep } from 'lodash';

import { Action } from 'patternfly-ng/action/action';
import { ListConfig } from 'patternfly-ng/list/basic-list/list-config';
import { ListEvent } from 'patternfly-ng/list/list-event';
//import { EmptyStateConfig } from 'patternfly-ng/empty-state/empty-state-config';
import { ActionConfig } from 'patternfly-ng/action/action-config';
import { FilterType } from 'patternfly-ng/filter/filter-type';
import { FilterField } from 'patternfly-ng/filter/filter-field';
import { FilterConfig } from 'patternfly-ng/filter/filter-config';
import { Filter } from 'patternfly-ng/filter/filter';
import { FilterEvent } from 'patternfly-ng/filter/filter-event';
import {GroupsService} from './service/group.service';
//import { Observable, of, forkJoin } from 'rxjs';
//import { switchMap, map } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';




@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-area-vinte',
  templateUrl: './area-vinte.component.html',
  styleUrls: ['./area-vinte.component.css']
})
export class AreaVinteComponent implements OnInit {

  actionsText: string = 'hola  aqui';
  allItems: any[];
  items: any[];
  itemsAvailable: boolean = true;
  listConfig: ListConfig;
  selectType: string = 'checkbox';
  filterConfig: FilterConfig;
  filtersText: string = '';
  actionConfig: ActionConfig;
  nombreGrupoQueries: any[];
  descripGrupoQueries: any[];

  constructor(
    private grupoService: GroupsService,
    private router: Router) {
    
  }

  getUserGroups(){
     this.grupoService.getGroups().subscribe(clientes => {      
      this.allItems = clientes;
      this.items = cloneDeep(this.allItems);
      
      let dataNameGrupo=[];
      let dataDescripGrupo=[];

     // console.log("GRUPOS: ", this.items);
      this.items.forEach((element, index) => {
      
        if (element.displayName) {
            let nameGrupo={
              id:index+1,
              value:element.displayName
            }
            dataNameGrupo.push(nameGrupo); 
        }
        if (element.description) {
          let descripGrupo={
            id:index+1,
            value:element.description
          }
          dataDescripGrupo.push(descripGrupo); 
        }
      });

      this.nombreGrupoQueries=cloneDeep(dataNameGrupo);
      this.descripGrupoQueries=cloneDeep(dataDescripGrupo);
      this.filterConfig.fields[0].queries=cloneDeep(dataNameGrupo);
      this.filterConfig.fields[1].queries=cloneDeep(dataDescripGrupo);

    });
  }

  ngOnInit(): void {
    console.log('init ng');

    this.filterConfig = {
      fields: [
        {
        id: 'nombre',
        title: 'Nombre',
        placeholder: 'Teclear y elegir',
        type: FilterType.TYPEAHEAD,
        queries: [],
      }, {
        id: 'descripcion',
        title: 'Descripción',
        placeholder: 'Teclear y elegir',
        type: FilterType.TYPEAHEAD,
        queries: [],
      }
    ] as FilterField[],
      appliedFilters: []
    } as FilterConfig;
          
    this.getUserGroups();
    

    this.actionConfig = {
      primaryActions: [{
        id: 'editaArea',
        title: 'Editar',
        tooltip: 'Edita Área'
      }],
      moreActions: [],
    } as ActionConfig;

    this.listConfig = {
      dblClick: false,
      multiSelect: false,
      selectItems: false,
      selectionMatchProp: 'name',
      showCheckbox: false,
      useExpandItems: false
    } as ListConfig;

    this.listConfig = {
      dblClick: false,
      multiSelect: false,
      selectItems: false,
      selectionMatchProp: 'name',
      showCheckbox: false,
      showRadioButton: false,
      useExpandItems: true
    } as ListConfig;

  }


  applyFilters(filters: Filter[]): void {
    this.items = [];
    if (filters && filters.length > 0) {
      this.allItems.forEach((item) => {
        if (this.matchesFilters(item, filters)) {
          this.items.push(item);
        }
      });
    } else {
      this.items = this.allItems;
    }
    this.filterConfig.resultsCount = this.items.length;
  }

  filterChanged($event: FilterEvent): void {
    this.filtersText = '';
    $event.appliedFilters.forEach((filter) => {
      this.filtersText += filter.field.title + ' : ' + filter.value + '\n';
    });
    this.applyFilters($event.appliedFilters);
    this.filterFieldSelected($event);
  }

  matchesFilter(item: any, filter: Filter): boolean {
    let match = true;
    //let re = new RegExp(filter.value, 'i');
    if (filter.field.id === 'nombre') {
      match = item.displayName === filter.value;
    } else if (filter.field.id === 'descripcion') {
      match = item.description === filter.value;
    }  
    return match;
  }

  matchesFilters(item: any, filters: Filter[]): boolean {
    let matches = true;
    filters.forEach((filter) => {
      if (!this.matchesFilter(item, filter)) {
        matches = false;
        return matches;
      }
    });
    return matches;
  }


  ngDoCheck(): void {
  }

 

  // Actions

  handleAction($event: Action, item: any): void {
    console.log('Ejecuta');
    console.log($event);
    console.log(item);
    this.router.navigate(['areaPoliticas'], { queryParams: { idArea: item.id } });
    
  }

  handleSelectionChange($event: ListEvent): void {
    this.actionsText = $event.selectedItems.length + ' items selected\r\n' + this.actionsText;
  }

  handleClick($event: ListEvent): void {
    this.actionsText = $event.item.name + ' clicked\r\n' + this.actionsText;
  }

  handleDblClick($event: ListEvent): void {
    this.actionsText = $event.item.name + ' double clicked\r\n' + this.actionsText;
  }

  // Row selection

  updateItemsAvailable(): void {
    this.items = (this.itemsAvailable) ? cloneDeep(this.allItems) : [];
  }

  updateSelectionType(): void {
    if (this.selectType === 'checkbox') {
      this.listConfig.selectItems = false;
      this.listConfig.showCheckbox = true;
      this.listConfig.showRadioButton = false;
    } else if (this.selectType === 'radio') {
      this.listConfig.selectItems = false;
      this.listConfig.showCheckbox = false;
      this.listConfig.showRadioButton = true;
    } else if (this.selectType === 'row') {
      this.listConfig.selectItems = true;
      this.listConfig.showCheckbox = false;
      this.listConfig.showRadioButton = false;
    } else {
      this.listConfig.selectItems = false;
      this.listConfig.showCheckbox = false;
      this.listConfig.showRadioButton = false;
    }
  }
    // Filter queries for type ahead
    filterQueries($event: FilterEvent) {

      const index = (this.filterConfig.fields as any).findIndex((i: any) => i.id === $event.field.id);
      let val = $event.value.trim();

      switch (this.filterConfig.fields[index].id) {
        case "nombre":
            let data1= this.nombreGrupoQueries.filter((item: any) => {
              if (item.value) {
                return (item.value.toLowerCase().indexOf(val.toLowerCase()) > -1);
              } else {
                return true;
              }
            })
            this.filterConfig.fields[index].queries = data1;
          
          break;
        case "descripcion":
            let data2= this.descripGrupoQueries.filter((item: any) => {
              if (item.value) {
                return (item.value.toLowerCase().indexOf(val.toLowerCase()) > -1);
              } else {
                return true;
              }
            })
            this.filterConfig.fields[index].queries = data2;
            //console.log("FILTER QUERIS5: ", this.filterConfig.fields[index].queries);
          
          break;
        default:
          break;
      }
    
  }


  filterFieldSelected($event: FilterEvent): void {
    this.filterConfig.fields.forEach((field) => {

      switch (field.id) {
        case "nombre":
          field.queries = this.nombreGrupoQueries;
          break;
        case "descripcion":
          field.queries = this.descripGrupoQueries;
          break;
        default:
          break;
      }
     
    });
  }

}
