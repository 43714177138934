import { Component, OnInit } from '@angular/core';
import {FooterVinteComponent} from '../footer/footer.component';

@Component({
  selector: 'logintemplate-dashboard',
  templateUrl: './logintemplate.component.html',
  styleUrls: ['./logintemplate.component.css']
})
export class LoginTemplateComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}