import { ChangeDetectorRef,
  Component,
  OnInit,
  ViewEncapsulation } from '@angular/core';
  import { Router } from '@angular/router';
  import { VerticalNavigationItem } from 'patternfly-ng/navigation/vertical-navigation/vertical-navigation-item';
import { AuthService } from './auth/auth.service';
import { SwitchService } from './vinteServices/switch.service';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-root',
  styles: [`    
  .faux-layout {
    position: fixed;
    top: 37px;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: blue;
    padding-top: 15px;
    z-index: 1100;
  }
  .example-page-container.container-fluid {
    position: fixed;
    top: 37px;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: blue;
    padding-top: 15px;
  }

  .hide-vertical-nav {
    margin-top: 15px;
    margin-left: 30px;
  }
  
  .navbar-brand-txt {
    line-height: 34px;
  }
`],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
  
  showExample: boolean = true;// para que no salga el boton debe de estar en true
  navigationItems: VerticalNavigationItem[];
  actionText: string = '';
  modalSwitch:Boolean;

  constructor(private chRef: ChangeDetectorRef, 
    private router: Router,
    private authService: AuthService,
    private modalSS: SwitchService) { }

  ngOnInit(): void {
    this.authService.initAuth();

    this.modalSS.$modal.subscribe(
      (valor)=>{
        this.modalSwitch=valor;
      }
    )

    this.navigationItems = [
      {
        title: 'Dashboard',
        iconStyleClass: 'fa fa-dashboard',
        url: '/cards'
      },
     
      
      {
        title: 'Administración',
        iconStyleClass: 'fa fa-users',
        
        children: [
           {
            title: 'Politicas',
            url: '/politicas',
            
          },
          {
            title: 'Areas',
            url: '/area',
    
          },
        
          {
            title: 'Areas > Politicas',
            url: '/areaPoliticas',
    
          },
         
          {
            title: 'Usuarios',
            url: '/list',           
          },
         
          


            
        /*    children: [
              {
                title: 'Delicatissimi',
                url: '/verticalnavigation/amet/detracto/delicatissimi'
              },
              {
                title: 'Aliquam',
                url: '/verticalnavigation/amet/detracto/aliquam'
              },
              {
                title: 'Principes',
                url: '/verticalnavigation/amet/detracto/principes'
              }
            ]*/
          //},

         // {
         //   title: 'Agregar usuarios',
          /*  children: [
              {
                title: 'Convenire',
                url: '/verticalnavigation/amet/mediocrem/convenire'
              },
              {
                title: 'Nonumy',
                url: '/verticalnavigation/amet/mediocrem/nonumy'
              },
              {
                title: 'Deserunt',
                url: '/verticalnavigation/amet/mediocrem/deserunt'
              }
            ]*/
          //},//
         /* {
            title: 'Editar usuario',
          /*  children: [
              {
                title: 'Aeque',
                url: '/verticalnavigation/amet/corrumpit/aeque'
              },
              {
                title: 'Delenit',
                url: '/verticalnavigation/amet/corrumpit/delenit'
              },
              {
                title: 'Qualisque',
                url: '/verticalnavigation/amet/corrumpit/qualisque'
              }
            ]*/
      //},
        /*  {
            title: 'urbanitas',
            url: '/verticalnavigation/amet/urbanitas'
          }*/
        ]
      },
      
      {
        title: 'Mis Políticas Internas',
        iconStyleClass: 'fa fa-user',
        url: '/profile'
      },
      {
        title: 'Plíticas Modal',
        iconStyleClass: 'fa fa-user',
        url: '/aboutmodal'
      }
    ];
  }

  toggleExample(): void {
    this.showExample = !this.showExample;
    this.chRef.detectChanges();
  }

  onItemClicked($event: VerticalNavigationItem): void {
    this.actionText += 'Item Clicked: ' + $event.title + '\n';
  }

  onNavigation($event: VerticalNavigationItem): void {
    this.actionText += 'Navigation event fired: ' + $event.title + '\n';
  }

  openModal(){
    this.modalSwitch=true;
  }

}