import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { cloneDeep } from 'lodash';
import { Router } from '@angular/router';
import { VinteService } from '../vinteServices/vinte.service';
@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.css']
})
export class PdfViewerComponent implements OnInit {
  link: string;
  zoom:any=100;
  pdfZoom:any = 1.2;
  docs:any={};
  codigoPolitica:string;
  trackPoliticas:any={};
  route:string;
  src="";

  constructor(private router:ActivatedRoute,
    private routerNav: Router,
    private politicasService: VinteService) { }

  onSelect():void{
    this.routerNav.navigate(['politicasView'], { queryParams: { codigoPolit: this.codigoPolitica} });
    //this.router.navigate(['pdf'], { queryParams:[doc]});
  }

  ngOnInit() {
    this.docs=this.router.snapshot.queryParams;
    this.codigoPolitica=this.docs.nobreItem;
    this.route= localStorage.getItem("Route");
    //console.log("Router: ",this.route);

    this.src= cloneDeep(this.docs.idDoc);
    //console.log(this.src);

   /* if (this.route==="Profile" || this.route==="Politicas") {
      this.trackEventPol(this.docs);
    }*/
    
  }

  removeZoom(){
    this.pdfZoom -=.1;
    this.zoom= (this.pdfZoom*100).toFixed(0);
    this.zoom=this.zoom-20;
    console.log("===ZOOM===");
    console.log(this.zoom);

    if (this.zoom<=50){
      this.pdfZoom=0.7;
      this.zoom=(this.pdfZoom*100).toFixed(0);
      this.zoom=this.zoom-20;
    // this.zoom=this.zoom-30;
    }

   // this.pdfZoom=this.pdfZoom.toFixed(2);
    console.log(this.pdfZoom.toFixed(2))
  }

  addZoom(){
    this.pdfZoom +=.1;
    this.zoom= (this.pdfZoom*100).toFixed(0);

    this.zoom=this.zoom-20;
    console.log("===ZOOM===");
    console.log(this.zoom); 
    
    //this.pdfZoom=this.pdfZoom.toFixed(2);
    if (this.zoom>=200){
      this.pdfZoom=2.2;
      this.zoom=(this.pdfZoom*100).toFixed(0);
      this.zoom=this.zoom-20; 
    }
    console.log(this.pdfZoom.toFixed(2))
  }

}
