import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'footervinte',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterVinteComponent implements OnInit {

  constructor(
    private router: Router,
  ) { }
  onSelect():void{
    this.router.navigate(['convenio'], { queryParams: { convenio: ''} });
    //this.router.navigate(['pdf'], { queryParams:[doc]});
  }

  ngOnInit() {
  }

}