import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'footerinterno',
  templateUrl: './footerinterno.component.html',
  styleUrls: ['./footerinterno.component.css']
})
export class FooterInternoComponent implements OnInit {

  constructor(
    private router: Router,
    private snapshot:ActivatedRoute
  ) { 
  }

  onSelect():void{
    this.router.navigate(['convenioInPortal'], { queryParams: { convenio: "profile"} });
    //this.router.navigate(['pdf'], { queryParams:[doc]});
  }


  ngOnInit() {

    
  }



}