import { Component, OnInit,ViewEncapsulation,TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
//import { DonutChartConfig } from 'patternfly-ng/chart/donut-chart/';
import { cloneDeep } from 'lodash';
import { Action } from 'patternfly-ng/action/action';
import { ListConfig } from 'patternfly-ng/list/basic-list/list-config';
import { ListEvent } from 'patternfly-ng/list/list-event';
import { EmptyStateConfig } from 'patternfly-ng/empty-state/empty-state-config';
//import { ActionConfig } from 'patternfly-ng/action/action-config';
import { VinteService } from '../vinteServices/vinte.service';
import { NotificationService } from '../notification/notification-service/notification.service';
import { NotificationType } from '../notification/notification-type';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-metricas-politicas',
  templateUrl: './metricas-politicas.component.html',
  styleUrls: ['./metricas-politicas.component.css']
})
export class MetricasPoliticasComponent implements OnInit {
  DATE_STATE:boolean=false;
  VALID_DATE_STATE:Boolean=false;
  SPINNERE_STATE:boolean=false;
  STATUS_STATE:boolean=true;
  LIST_STATE:boolean=false;

  dateForm: FormGroup;
  notifications: any=[];
  allItems: any[];
  items: any[];
  totalPol:any=0;
  totalConsultPol:number=0;
  actionsText: string = '';//---
  emptyStateConfig: EmptyStateConfig;
  itemsAvailable: boolean = true;//---
  listConfig: ListConfig;
  selectType: string = 'checkbox';

  constructor(
    private  politicasService: VinteService,
    public notificationService: NotificationService,
    public formBuilder: FormBuilder
    ) {
      this.notifications = notificationService.getNotifications();
      this.dateForm = this.formBuilder.group({
      startDate: ['', [Validators.required]],
      endDate: ['', [Validators.required]],
      apartadoPI : [''],
      apartadoPFV : [''],
      apartadoXANTE : [''],
      apartadoMOVinte : [''],
      apartadoPOEmobel : [''],
      guiasVinte : [''],
    });
   }

  ngOnInit() {

    this.emptyStateConfig = {
      title: 'Buscando...',
    } as EmptyStateConfig;

    this.listConfig = {
      dblClick: false,
      emptyStateConfig: this.emptyStateConfig,
      multiSelect: false,
      selectItems: false,
      selectionMatchProp: 'name',
      showCheckbox: false,
      showRadioButton: false,
      useExpandItems: true
    } as ListConfig;
  }

  ngDoCheck(): void {
  }

  // Actions

  handleAction($event: Action, item: any): void {
    if ($event.id === 'start' && item !== null) {
      item.started = true;
    }
    this.actionsText = $event.title + ' selected\r\n' + this.actionsText;
  }

  handleSelectionChange($event: ListEvent): void {
    this.actionsText = $event.selectedItems.length + ' items selected\r\n' + this.actionsText;
  }

  handleClick($event: ListEvent): void {
    this.actionsText = $event.item.name + ' clicked\r\n' + this.actionsText;
  }

  handleDblClick($event: ListEvent): void {
    this.actionsText = $event.item.name + ' double clicked\r\n' + this.actionsText;
  }

  // Row selection

  updateItemsAvailable(): void {
    this.items = (this.itemsAvailable) ? cloneDeep(this.allItems) : [];
  }

  updateSelectionType(): void {
    if (this.selectType === 'checkbox') {
      this.listConfig.selectItems = false;
      this.listConfig.showCheckbox = false;
      this.listConfig.showRadioButton = false;
    } else if (this.selectType === 'radio') {
      this.listConfig.selectItems = false;
      this.listConfig.showCheckbox = false;
      this.listConfig.showRadioButton = true;
    } else if (this.selectType === 'row') {
      this.listConfig.selectItems = false;
      this.listConfig.showCheckbox = false;
      this.listConfig.showRadioButton = false;
    } else {
      this.listConfig.selectItems = false;
      this.listConfig.showCheckbox = false;
      this.listConfig.showRadioButton = false;
    }
  }

  async timeMetricas(){
    this.SPINNERE_STATE=true;
    this.STATUS_STATE=true;
    this.LIST_STATE=true;
   
    const dataMetrics= await this.buildDataMetricas();
    
    this.politicasService.getTimeMetricasPol(dataMetrics)
    .subscribe((res:any)=>{
      this.SPINNERE_STATE=false;
      //console.log("STATUS :");
      
      if (res.data=='Failed') {
        this.failedConsulted();
        return;
      }

      this.allItems=res.data;
      this.changeEmpatyState();

      this.allItems.sort(function(a, b){return b.CountPol - a.CountPol});
      //let top=this.allItems.slice(0,3);
      
      this.items = cloneDeep(this.allItems);
      this.totalPol=this.items.length;

      let totalP=0;
      this.items.forEach(function(element) {
        totalP=totalP+element.CountPol
        var documentos =element.DataPol;
        
        var ordering = {},sortOrder = ['Política','Proceso','Formatos','Anexos','Manuales','Presentación y evaluación',''];
        for (var i=0; i<sortOrder.length; i++)
            ordering[sortOrder[i]] = i;
            documentos.sort( function(a, b) {
              return (ordering[a.clasificación] - ordering[b.clasificación]);
            });
    
      });
      this.totalConsultPol=totalP;
        
    },error=>{
      this.SPINNERE_STATE=false;
      this.STATUS_STATE=false;
      this.changeEmpatyState();
      console.log("[ERROR TIME-METRICAS]: ",error);
      this.notificationService.message(
        NotificationType.WARNING,
        `Tenemos algo de dificultades,  Error: ${error.statusText}, Code: ${error.status}`,
        '',
        null,
        null,
        null);
    })

  }

  buildDataMetricas(){
    let aparados = [];
  
    if (this.dateForm.value.apartadoPI) {
      aparados.push('Políticas Institucionales');
    } 
    if (this.dateForm.value.apartadoPFV) {
      aparados.push('Políticas Fundación VINTE');
    }
    if (this.dateForm.value.apartadoXANTE) {
      aparados.push('Políticas XANTE');
    }
    if (this.dateForm.value.apartadoMOVinte) {
      aparados.push('Manuales de operación VINTE');
    }
    if (this.dateForm.value.apartadoPOEmobel) {
      aparados.push('Manuales de operación EMOBEL');
    }
    if (this.dateForm.value.guiasVinte) {
      aparados.push('Guías VINTE');
    }

    return {
      startDate: this.dateForm.value.startDate,
      endDate: this.dateForm.value.endDate,
      apartados: aparados
    }

  }

  dateChenge(e){
    const fechaIn=this.dateForm.value.startDate;
    const fechaFin=this.dateForm.value.endDate;
    const apartadoPI=this.dateForm.value.apartadoPI;
    const apartadoPFV=this.dateForm.value.apartadoPFV;
    const apartadoXANTE=this.dateForm.value.apartadoXANTE;
    const apartadoMOVinte=this.dateForm.value.apartadoMOVinte;
    const apartadoPOEmobel=this.dateForm.value.apartadoPOEmobel;
    const guiasVinte=this.dateForm.value.guiasVinte;
    
    if (fechaIn!=='' && fechaFin!=='') {

      if (fechaIn<fechaFin) {
          this.DATE_STATE=false;

          if (fechaIn && fechaFin && 
            (apartadoPI || apartadoPFV || apartadoXANTE || apartadoMOVinte || apartadoPOEmobel || guiasVinte)) {
            this.VALID_DATE_STATE=true;
          }else{
            this.VALID_DATE_STATE=false;
          }
          //this.VALID_DATE_STATE=true;  
      } else {
          this.DATE_STATE=true;
          this.VALID_DATE_STATE=false;    
      }
    }



  }

  changeEmpatyState(){
    this.emptyStateConfig = {
      iconStyleClass: 'pficon-warning-triangle-o',
      title: 'No se encontraron registros',
      info: 'Vuelva a intentar una nueva consulta',
    } as EmptyStateConfig;

    this.listConfig.emptyStateConfig=this.emptyStateConfig;
  }

  failedConsulted(){
    this.SPINNERE_STATE=false;
    this.STATUS_STATE=false;
    this.changeEmpatyState();
    this.items = cloneDeep([]);
 
    this.notificationService.message(
      NotificationType.WARNING,
      `Tenemos algo de dificultades,  Error: Failed Server`,
      '',
      null,
      null,
      null);

      this.totalPol=0;
      this.totalConsultPol=0;

  } 

}
