import {
  Component,
  OnInit,
  TemplateRef,
  ViewEncapsulation
} from '@angular/core';
import { cloneDeep } from 'lodash';

import { InfoStatusCardConfig } from 'patternfly-ng/card/info-status-card/info-status-card-config';
import { HomeService } from '../home/home.service';
import * as MicrosoftGraph from "@microsoft/microsoft-graph-types"
import { Subscription, Observable } from 'rxjs';



import { Action } from 'patternfly-ng/action/action';
import { ListConfig } from 'patternfly-ng/list/basic-list/list-config';
import { ListEvent } from 'patternfly-ng/list/list-event';
import { EmptyStateConfig } from 'patternfly-ng/empty-state/empty-state-config';
import { ActionConfig } from 'patternfly-ng/action/action-config';

import { VinteService } from '../vinteServices/vinte.service';
import { FilterType } from 'patternfly-ng/filter/filter-type';
import { FilterField } from 'patternfly-ng/filter/filter-field';
import { FilterConfig } from 'patternfly-ng/filter/filter-config';
import { Filter } from 'patternfly-ng/filter/filter';
import { FilterEvent } from 'patternfly-ng/filter/filter-event';

import {GroupsService } from '../area-vinte/service/group.service'
import {UsersService } from '../area-vinte/service/user.service'
import { switchMap, map } from 'rxjs/operators';
import {HttpService} from '../shared/http.service'
import { tickStep } from 'd3';
import { Router } from '@angular/router';
import { _ } from 'underscore';


@Component({
  selector: 'app-mis-politicas',
  templateUrl: './mis-politicas.component.html',
  styleUrls: ['./mis-politicas.component.css']
})
export class MisPoliticasComponent implements OnInit {

  me: MicrosoftGraph.User;
  subsGetMe: Subscription;
  subsGetUsers: Subscription;
  groupSelectedName:string;
  actionsText: string = 'hola  aqui';
  allItems: any[];
  emptyStateConfig: EmptyStateConfig;
  items: any[];
  itemsAvailable: boolean = true;
  listConfig: ListConfig;
  selectType: string = 'checkbox';
  Id:string;
  politicas:any={};
  filterConfig: FilterConfig;

  filtersText: string = '';

  documentss:any;
  groups:any[];

  constructor(
    private homeService: HomeService, 
    private politicasService: VinteService,
    private groupService: GroupsService,
    private userService: UsersService,
    private httpService: HttpService,
    private router: Router
  ) { }

  onSelect(group:any):void{
    this.router.navigate(['profile'], { queryParams: { idGroup:  group.id, nameGroup:group.displayName} });
  }

  getAllPlocies():void{
    let idGpo = "Fortigate0"
    this.httpService.getMe()
    .subscribe(x=>{
      this.Id = x.id;
      this.getUserGroups()
        .subscribe(y=>{          
          console.log(y.groups);
          let id = y.groups[0].id;
          console.log(y.groups[0]);
          
          this.groups=y.groups;
          console.log("---aqui----")
         console.log(this.groups);

            this.groups.forEach(function (elemento, indice, array) {
                  var displayName =elemento.displayName;
                  console.log("---aqui--2----")
                  console.log(displayName);

                  if (displayName=="politicas") {
                    
                  } else {

                   // alert("No se encontro el grupo Políticas");
                    
                  }
                  
                  //var docs=_.sortBy (doc, 'nombre'); 
                  //console.log(doc);
                 // console.log(docs);
                //  this.documentss=docs;
                 // elemento.documentos.forEach(function (element, ind, array) {
    
                //});
              });

          this.groupSelectedName = y.groups[0].displayName;
          console.log( this.groupSelectedName);
          this.politicasService.getAreaByGroup(id)
            .subscribe(politicas=>{
              console.log(politicas);
              console.log(politicas[0].politicas);
                this.allItems = politicas[0].politicas;
                this.items = cloneDeep(this.allItems);
    
                 
    
             
              //console.log(doc);
    
            })
        });
    })
    
    }

  ngOnInit() {
    this.subsGetMe = this.homeService.getMe().subscribe(me => this.me = me); 
    this.getAllPlocies();

  }


  getUserGroups(): Observable<IUserGroups> {



    return this.groupService.getGroups().pipe(switchMap(grs => {

      return this.groupService.getGroupsByUser(this.Id).pipe(map(g => {

        let ugroups = g.map(e => {          
          let io = grs.find(r => r.id === e.id);
          //console.log('sel-')
          //console.log(e.sel);
          //e.sel = !!io;
          //console.log(grs)

          return io;
        });

        return { user: null, groups: ugroups };
      }))
    }))
  
}

}
