import { ChangeDetectorRef,
    Component,
    OnInit,
    ViewEncapsulation } from '@angular/core';
    import { Router } from '@angular/router';
    import { VerticalNavigationItem } from 'patternfly-ng/navigation/vertical-navigation/vertical-navigation-item';
    import { AuthService } from '../../auth/auth.service'
    import { VinteService } from '../../vinteServices/vinte.service';
    import { HttpService } from '../../shared/http.service';
    import * as MicrosoftGraph from "@microsoft/microsoft-graph-types";
    import { SwitchService } from '../../vinteServices/switch.service';
@Component({
    encapsulation: ViewEncapsulation.None,
    selector: 'layout',
    styles: [`    
    .faux-layout {
      position: fixed;
      top: 37px;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: blue;
      padding-top: 15px;
      z-index: 1100;
    }
    .example-page-container.container-fluid {
      position: fixed;
      top: 37px;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: blue;
      padding-top: 15px;
    }
  
    .hide-vertical-nav {
      margin-top: 15px;
      margin-left: 30px;
    }
    
    .navbar-brand-txt {
      line-height: 34px;
    }
  `],  
  templateUrl: './dashboard.html',
  styleUrls: ['./dashboard.css']
})
export class DashboardLayoutComponent implements OnInit {
  
    showExample: boolean = true;// para que no salga el boton debe de estar en true
    navigationItems: VerticalNavigationItem[];
    actionText: string = '';
    me: MicrosoftGraph.User;
    modalSwitch:Boolean;
  
    constructor(
      private chRef: ChangeDetectorRef, 
      private router: Router,
      private authService: AuthService,
      private vinteService: VinteService,
      private httpService: HttpService,
      private modalSS: SwitchService
    ) { }
  
    logout(){      
      this.authService.logout();
    }

    ngOnInit(): void {
      this.modalSS.$modal.subscribe(
        (valor)=>{
          this.modalSwitch=valor;
        }
      );
      this.openModal();

      this.getUser();
      this.authService.memberOfAdminOk()
        .subscribe(x=>{
          if(x){
            //let admin="true";
            //localStorage.setItem("Admin", admin);
            //this.createAdminMenu()
            //this.handleUSerAdmin();;
          }else{
            //this.handleUSerAdmin();
          }
        })
    }

    getUser():void{
      this.httpService.getMe()
      .subscribe( me =>{
        this.me=me;
        this.handleUSerAdmin();
      })
    }

    handleUSerAdmin():void{      
        this.vinteService.userAdmin(this.me.mail || this.me.userPrincipalName)
          .subscribe((x:any)=>{
            if (x.data) {
              let admin="true";
              localStorage.setItem("Admin", admin);
              this.createAdminMenu();
            }else{
              let admin="false";
              localStorage.setItem("Admin", admin);
              this.createNormalMenu();
              this.router.navigate(['profile']);
            }  
          })
    }

    createAdminMenu(){
      this.navigationItems = [
        {
          title: 'Administración',
          iconStyleClass: 'fa fa-users',
          
          children: [
             {
              title: 'Políticas',
              url: '/politicas',
              
            },
            {
              title: 'GruposAD',
              url: '/area',
      
            },
          
            {
              title: 'GruposAD_Políticas',
              url: '/areaPoliticas',
      
            },
            {
              title:'Métricas',
              children: [
                {
                 title: 'Portal Políticas',
                 url: '/metricas-portal',
               },{
                title: 'Consultas Por Políticas',
                url: '/metricas-consultas_por_politicas', 
              }]
            }          
          ]
        },{
          title:'Código de Ética',
          iconStyleClass:'fa fa-file-text',
          url:'/codigoEtica'
        }, 
        {
          title:'Decálogo de integridad VINTE',
          iconStyleClass:'fa fa-file-text',
          url:'/decalogo-integridad-vinte'
        },  
        {
          title: 'Políticas Institucionales',
          iconStyleClass: 'fa fa-user',
          url: '/profile'
        },
        {
          title: 'Manuales de Op. VINTE',
          iconStyleClass: 'fa pficon-catalog',
          url: '/mo-vinte'
        },
        {
          title: 'Manuales de Op. EMOBEL',
          iconStyleClass: 'fa pficon-catalog',
          url: '/mo-emobel'
        },
        {
          title: 'Políticas Fundación VINTE',
          iconStyleClass: 'fa pficon-catalog',
          url: '/politicas-fv'
        },
        {
          title: 'Políticas XANTE',
          iconStyleClass: 'fa pficon-catalog',
          url: '/politicas-xante'
        },
        {
          title: 'Guías VINTE',
          iconStyleClass: 'fa pficon-catalog',
          url: '/guias-vinte'
        }
        
      ];
    }

    createNormalMenu(){
      this.navigationItems = [
        {
          title:'Código de Ética',
          iconStyleClass:'fa fa-file-text',
          url:'/codigoEtica'
        },
        {
          title:'Decalogo integridad vinte',
          iconStyleClass:'fa fa-file-text',
          url:'/decalogo-integridad-vinte'
        },             
        {
          title: 'Políticas Institucionales',
          iconStyleClass: 'fa fa-user',
          url: '/profile'
        },{
          title: 'M. Operación Vinte',
          iconStyleClass: 'fa pficon-catalog',
          url: '/mo-vinte'
        },
        {
          title: 'M. Operación Emobel',
          iconStyleClass: 'fa pficon-catalog',
          url: '/mo-emobel'
        },
        {
          title: 'Políticas Fundación VINTE',
          iconStyleClass: 'fa pficon-catalog',
          url: '/politicas-fv'
        },
        {
          title: 'Políticas XANTE',
          iconStyleClass: 'fa pficon-catalog',
          url: '/politicas-xante'
        },
        {
          title: 'Guías VINTE',
          iconStyleClass: 'fa pficon-catalog',
          url: '/guias-vinte'
        }
        
      ];
    }
  
    toggleExample(): void {
      this.showExample = !this.showExample;
      this.chRef.detectChanges();
    }
  
    onItemClicked($event: VerticalNavigationItem): void {
      this.actionText += 'Item Clicked: ' + $event.title + '\n';
    }
  
    onNavigation($event: VerticalNavigationItem): void {
      this.actionText += 'Navigation event fired: ' + $event.title + '\n';
    }

    openModal(){
      this.modalSwitch=true;
    }
  
  }