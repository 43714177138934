import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ToastNotificationModule } from 'patternfly-ng/notification';
import { VerticalNavigationModule } from 'patternfly-ng/navigation';
import { BsDropdownConfig, BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { Routes, RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { ProfileComponent } from './profile/profile.component';

import { InfoStatusCardModule } from 'patternfly-ng/card';
import { AboutModalExampleComponent } from './aboutModal/about-modal-example/about-modal-example.component';

import { AboutModalModule } from 'patternfly-ng/modal';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ListBasicExampleComponent } from './list-basic-example/list-basic-example.component';

import { ListModule } from 'patternfly-ng/list';
//import { BsDropdownConfig, BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TooltipConfig, TooltipModule } from 'ngx-bootstrap/tooltip';
import { ActionModule } from 'patternfly-ng/action';
import { PoliticasComponent } from './politicas/politicas.component';
import { RolesComponent } from './roles/roles.component';
import { AgregarUsuarioComponent } from './agregar-usuario/agregar-usuario.component';
import { NuevaPoliticaComponent } from './nueva-politica/nueva-politica.component';
import { AreaVinteComponent } from './area-vinte/area-vinte.component';
import { NuevaAreaComponent } from './nueva-area/nueva-area.component';
import { AreaPoliticaComponent } from './area-politica/area-politica.component';
import { BuscadorComponent } from './buscador/buscador.component';

import { GroupsService } from './area-vinte/service/group.service';
import { UsersService } from './area-vinte/service/user.service';

// Individual module import
import { TableModule } from 'patternfly-ng/table';

// NGX Datatable
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ToolbarModule } from 'patternfly-ng/toolbar';

import { HttpClientModule } from '@angular/common/http';
import { FilterModule } from 'patternfly-ng/filter';


import { DashboardLayoutComponent } from './template/dashboard/dashboard';

import { LoginTemplateComponent } from './template/login/logintemplate.component'

import { routing } from './app.routing';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { HttpModule } from '@angular/http';

import { FormsModule, FormBuilder, ReactiveFormsModule } from '@angular/forms';

import { ToastNotificationListModule } from 'patternfly-ng/notification';

import { NotificationService } from './notification/notification-service/notification.service';
import { PdfViewerComponent } from './pdf-viewer/pdf-viewer.component';
import { PdfViewerModule } from 'ng2-pdf-viewer'; // <- import OrderModule

import {ProfilePipe} from '../app/profile/profile.pipe';
import { from } from 'rxjs';
import { MisPoliticasComponent } from './mis-politicas/mis-politicas.component';
import {FooterVinteComponent} from './template/footer/footer.component'
import { FooterInternoComponent } from './template/footer-inside/footerinterno.component';
import { PoliticasViewComponent } from './politicas-view/politicas-view.component';
import { PdfConvenioComponent } from './pdf-convenio/pdf-convenio.component';
import { CodigoEticaComponent} from './codigoEtica/codigoEtica.componet';
import { MetricasPortalComponent } from './metricas-portal/metricas-portal.component';
import { MetricasPoliticasComponent } from './metricas-politicas/metricas-politicas.component';
import { DonutChartModule } from 'patternfly-ng/chart';
import { ApartadoMoVinteComponent } from './apartado-mo-vinte/apartado-mo-vinte.component';
import { ApartadoMoEmobelComponent } from './apartado-mo-emobel/apartado-mo-emobel.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { PoliticasFVComponent } from './politicas-fv/politicas-fv.component';
import { ModalComponent } from './modal/modal.component';
import { ApartadoXanteComponent } from './apartado-xante/apartado-xante.component';
import { DecalogoIntegridadVinteComponent } from './decalogo-integridad-vinte/decalogo-integridad-vinte.component';
import { ApartdoGuiasVinteComponent } from './apartdo-guias-vinte/apartdo-guias-vinte.component';


@NgModule({
  declarations: [
    AppComponent,
    ProfileComponent,
    AboutModalExampleComponent,
    ListBasicExampleComponent,
    PoliticasComponent,
    RolesComponent,
    AgregarUsuarioComponent,
    NuevaPoliticaComponent,
    AreaVinteComponent,
    NuevaAreaComponent,
    AreaPoliticaComponent,
    BuscadorComponent,
    DashboardLayoutComponent,
    LoginTemplateComponent,
    HomeComponent,
    LoginComponent,
    PdfViewerComponent,
    ProfilePipe,
    MisPoliticasComponent,
    FooterVinteComponent,
    FooterInternoComponent,
    PoliticasViewComponent,
    PdfConvenioComponent,
    CodigoEticaComponent,
    MetricasPortalComponent,
    MetricasPoliticasComponent,
    ApartadoMoVinteComponent,
    ApartadoMoEmobelComponent,
    PoliticasFVComponent,
    ModalComponent,
    ApartadoXanteComponent,
    DecalogoIntegridadVinteComponent,
    ApartdoGuiasVinteComponent    
  ],
  imports: [
    BrowserModule,
    ToastNotificationModule,
    VerticalNavigationModule, 
    BsDropdownModule.forRoot(),
    routing,
    InfoStatusCardModule,
    AboutModalModule, ModalModule.forRoot(),
    ListModule,
    TooltipModule.forRoot(),
    ActionModule,
    NgxDatatableModule, //TableModule 
    ToolbarModule,
    HttpClientModule,
    FilterModule,
    FormsModule,
    ReactiveFormsModule,
    HttpModule,
    ToastNotificationListModule,
    PdfViewerModule,
    DonutChartModule,
    NgMultiSelectDropDownModule.forRoot()
  ],
  providers: [BsDropdownConfig, BsDropdownConfig, TooltipConfig, GroupsService, UsersService, FormBuilder, NotificationService],
  bootstrap: [AppComponent]
})
export class AppModule { }
