import { HttpService } from '../../shared/http.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class UsersService {

    constructor(
        public sharedService: HttpService) {
    }

    public getUsers(): Observable<IUser[]> {
        return new Observable(obs => {
            let data = [];

            let ajax = (url: string) => {
                this.sharedService.client.api(url).get().then((res: any) => {
                    let urlnext = res['@odata.nextLink'];
                    data = [...data, ...res.value];

                    if (urlnext) {
                        ajax(urlnext);
                    } else {
                        obs.next(data);
                        obs.complete();
                    }
                });
            }
            ajax('users');
        });
    }

    public patchUser(id: string, user: IUser): Observable<boolean> {
        return new Observable(obs => {
            this.sharedService.client.api(`users/${id}`).patch(user, (err, res) => {
                err ? obs.error(err) : obs.next(res);
                obs.complete();
            });
        });
    }

    public addUser(user: IUser): Observable<boolean> {

        return new Observable(obs => {
            this.sharedService.client.api(`users`).post(user, (err, res) => {
                err ? obs.error(err) : obs.next(res);
                obs.complete();
            });
        });
    }

    public getUser(id: string): Observable<IUser> {
        return new Observable(obs => {
            this.sharedService.client.api(`users/${id}`).get().then((res: IUser) => {
                obs.next(res);
                obs.complete();
            });
        });
    }

    public delete(id: string): Observable<any> {
        return new Observable(obs => {
            this.sharedService.client.api(`users/${id}`).delete((err, res) => {
                err ? obs.error(err) : obs.next();
                obs.complete();
            });
        });
    }
}
