import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
//import {HttpService} from '../shared/http.service';
//import {GroupsService } from '../area-vinte/service/group.service';
import { VinteService } from '../vinteServices/vinte.service';
import { cloneDeep } from 'lodash';
import { Router } from '@angular/router';
import * as MicrosoftGraph from "@microsoft/microsoft-graph-types";
import { HomeService } from '../home/home.service';

@Component({
  selector: 'app-politicas-view',
  templateUrl: './politicas-view.component.html',
  styleUrls: ['./politicas-view.component.css']
})
export class PoliticasViewComponent implements OnInit {
  me: MicrosoftGraph.User;
  Id:string;
  groupSelectedName:string;
  allItems: any[];
  items: any[];
  link:string;
  codigoPolitica:string;
  cod:string;
  nombre:string;
  datos:any=[];
  route:string;

  constructor(private router:ActivatedRoute,
    private politicasService: VinteService,
    private routerNav: Router,
    private homeService: HomeService) { }

    onSelect(doc:any,datos:any):void{
     
      this.trackEventPol(doc, datos);
      
      this.routerNav.navigate(['pdf'], { queryParams: { idDoc: doc.link, nombreDoc: doc.nombre ,nobreItem:datos.codigo,
                                                         nombrePol:datos.descripcion, clasificacion: doc.clasificación} });
      //this.router.navigate(['pdf'], { queryParams:[doc]});
    }


  getAllPlocies():void{

    this.politicasService.getPoliticas(null)   //obtener las políticas vinte desde service     
      .subscribe((politicas: any)=>{ 
        this.allItems=politicas;//políticas;//pasar un object a un  arreglo

        this.allItems.sort(function(a,b){  //ordenar las políticas 
          return a.codigo >b.codigo?1:a.codigo <b.codigo?-1:0
        });

        this.items = cloneDeep(this.allItems);//clonar las políticas 
        var docs =this.items.find((el)=>{// Encontrar una politica mediante el dodigo de politica
          console.log("CODIGO POLITICA:"+this.codigoPolitica);
          return el.codigo ==this.codigoPolitica;
        });

        if (docs.codigo!=undefined){
           this.cod=docs.codigo;
        }
   
      this.nombre=docs.nombre;
      this.datos=docs;
      this.datos.documentos.sort((a,b) => a.clasificación.localeCompare(b.clasificación));//ordenar documentos de políticas
    
      var ordering = {}, // map for efficient lookup of sortIndex
      sortOrder = ['Política', 'Manual de operación','Proceso','Formatos','Anexos', 'Guía','Presentación',
        'Manuales','Presentación y evaluación',''];//ordenamiento personalizado
      for (var i=0; i<sortOrder.length; i++)
      ordering[sortOrder[i]] = i;

      this.datos.documentos.sort( function(a, b) {
        return (ordering[a.clasificación] - ordering[b.clasificación]) || a.nombre.localeCompare(b.nombre);
      });       
    });
  }

  ngOnInit() {
    //this.link= this.router.snapshot.queryParams["codigoPolit"];
    this.route= localStorage.getItem("Route");
    this.codigoPolitica=this.router.snapshot.queryParams["codigoPolit"];
  
    this.getAllPlocies();
  }


  trackEventPol(dataDoc:any,dataPol:any):void{
   
    if (this.route=='Profile' || this.route=='MO-Emobel' || this.route == 'Politicas-XANTE' 
      || this.route == 'MO-Vinte' || this.route == 'Politicas-FV' || this.route == 'Guías-VINTE') {

        this.homeService.getMe().subscribe(me => {
          this.me=me;
          let trackPoliticas={
            Usuario:this.me.displayName,
            Apartado: dataPol.apartado,
            Politica: dataPol.nombre,
            Codigo:dataPol.codigo,
            NombreDoc:dataDoc.nombre,
            ClasificacionDoc:dataDoc.clasificación
          }
          this.politicasService.trackEventPoliticas(trackPoliticas);
        });
                
        }

  }

  
}
