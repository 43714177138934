import { Component, OnInit } from '@angular/core';
import { cloneDeep } from 'lodash';
import { VinteService } from '../vinteServices/vinte.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-decalogo-integridad-vinte',
  templateUrl: './decalogo-integridad-vinte.component.html',
  styleUrls: ['./decalogo-integridad-vinte.component.css']
})
export class DecalogoIntegridadVinteComponent implements OnInit {
  allItems: any[];
  items: any[];
  politica:any;

  constructor(private politicasService: VinteService,
    private router: Router) { }

  ngOnInit() {
    this.getAllPlocies();
  }

  getAllPlocies():void{
   
    this.politicasService.getPoliticas(null)    //evento para obtener los datos vinte desde service     
    .subscribe((politicas: any)=>{  
      //debugger;    
      this.allItems=politicas//politicas;//pasar un object a un  arreglo
     
      //this.allItems.sort((a,b) => a.codigo.localeCompare(b.codigo));//oredenamiento
      this.allItems.sort(function(a,b){
        return a.codigo >b.codigo?1:a.codigo <b.codigo?-1:0
       });
      //console.log("===POLITICAS C.E===");
      //console.log(this.allItems);

      this.items = cloneDeep(this.allItems);

      this.politica = this.items.find(
          (el)=>{
            return el.codigo == "Decálogo de integridad vinte"
          }
        );

      //console.log("===CODIGO DE ETICA===");
      //console.log(this.politica);

      let codigo =this.politica.codigo;
      let routerNav= this.router;
        
      this.politica.documentos.forEach(function(element) {
        var documentos =element;
      
        //console.log("--DOCUMENTOS C.E--");
        //console.log(documentos);
        //console.log(codigo);
        let Route="decalogo-integridad-vinte";
        localStorage.setItem("Route",Route);

        routerNav.navigate(['pdf'], { queryParams: { idDoc: element.link, nombreDoc: element.nombre ,nobreItem:codigo} });
         // this.router.navigate(['pdf'], { queryParams: { idDoc: element.link, nombreDoc: element.nombre ,nobreItem:""} });
         // this.routerNavigate(this.politica, documentos);
      });  

    },error=>{
      console.log(error);
      alert("tenemos algo de dificultades,  Error:"+error.statusText+error.status);
      //Swal.fire("tenemos algo de dificultades,  Error:"+error.status+'-'+error.statusText, this.titularAlerta, 'warning');
    });
  }

}
