import { Component, OnInit} from '@angular/core';
import { VinteService } from '../vinteServices/vinte.service';
import { FormBuilder, FormGroup, Validators,FormsModule, ReactiveFormsModule} from '@angular/forms';

import { Action } from 'patternfly-ng/action/action';
import { ListConfig } from 'patternfly-ng/list/basic-list/list-config';
import { ActionConfig } from 'patternfly-ng/action/action-config';

import { FilterType } from 'patternfly-ng/filter/filter-type';
import { FilterField } from 'patternfly-ng/filter/filter-field';
import { FilterConfig } from 'patternfly-ng/filter/filter-config';
import { cloneDeep } from 'lodash';

import { NotificationService } from '../notification/notification-service/notification.service';
import { NotificationType } from '../notification/notification-type';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment  from 'moment-timezone';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({
  selector: 'app-nueva-politica',
  templateUrl: './nueva-politica.component.html',
  styleUrls: ['./nueva-politica.component.css']
})
export class NuevaPoliticaComponent implements OnInit {

  politicas:any={
    codigo:"VTE-POL-"
  };
  documentos:any= [];
  document:any={};
  validator: any={};
  items: any[];
  notifications: any=[];
  politicasForm: FormGroup;
  activeEdit: boolean;
  politicaEdit: string;

  listConfig: ListConfig;
  selectType: string = 'checkbox';
  actionConfig: ActionConfig;
  filterConfig: FilterConfig;
  filtersText: string = '';

  token:string;
  //clasificacion:any[];
  opcionSeleccionado: string;

  uploadedFiles:Array <File>
  fileData:{}
  validSize:boolean;
  SpinnerState:boolean=false;
  file:string;
  fileName:string;
  
  listaClasificacion: any[] = [
    "Proceso",
    "Formatos",
    "Anexos",
    "Guía",
    "Presentación"
  ];
  clasificacionPolitica: any[] =  ['Política institucional'].concat(this.listaClasificacion);
  clasificacionManual: any[] = ['Manual de operación'].concat(this.listaClasificacion);
  clasificacion: any[]= [];

  areasPoliticas: any[];
  areasMOVinte: any[];
  areasMOEmobel: any[];
  listAreas: any[];

  dropdownList = [];
  selectedItems = [];
  dropdownSettings : IDropdownSettings = {};

  listAreasAll: any[];

  constructor(
    private  politicasService: VinteService,
    public formBuilder: FormBuilder,
    public notificationService: NotificationService,
    public router: ActivatedRoute) { 
      this.politicasForm = this.formBuilder.group({
        codigo: ['', [Validators.required, Validators.minLength(5)]],
        nombre: ['', [Validators.required, Validators.minLength(5)]],     
        //descripcion: ['', [Validators.required, Validators.minLength(5)]],
        //lastUpdated:['', [Validators.required,Validators.pattern(/^(?:(?:(0?[1-9]|1\d|2[0-8])[/](0?[1-9]|1[0-2])|(29|30)[/](0?[13-9]|1[0-2])|(31)[/](0?[13578]|1[02]))[/](0{2,3}[1-9]|0{1,2}[1-9]\d|0?[1-9]\d{2}|[1-9]\d{3})|(29)[/](0?2)[/](\d{1,2}(?:0[48]|[2468][048]|[13579][26])|(?:0?[48]|[13579][26]|[2468][048])00))$/)]],
        lastUpdated:['', [Validators.required,Validators.minLength(5)]],
        areaResponsable: ['', [Validators.required, Validators.minLength(2)]],
        apartado: [''],
        areasInvolucradas: [[], [Validators.required]],
      });
      this.notifications = notificationService.getNotifications();
    }

    capturar() {
      console.log(this.politicasForm.value.apartado);
      switch (this.politicasForm.value.apartado) {
        case 'Políticas Institucionales':
        case 'Políticas Fundación VINTE':
        case 'Políticas XANTE':
          this.clasificacion = this.clasificacionPolitica;
          this.listAreas = this.areasPoliticas;
          break;
        case 'Manuales de operación VINTE':
          this.clasificacion = this.clasificacionManual;
          this.listAreas = this.areasMOVinte;
          break;
        case 'Manuales de operación EMOBEL':
          this.clasificacion = this.clasificacionManual;
          this.listAreas = this.areasMOEmobel;   
          break;
        default:
          this.clasificacion = this.clasificacionPolitica;
          this.listAreas = this.listAreasAll;
          break;
      }
    }

    capturarClasificacion(event) {
      this.opcionSeleccionado = event.target.value;
    }

  agregarDocto(){
    let formData = new FormData();
    
    this.fileData={
      nombrePolitica:this.politicasForm.value.nombre,
      nombreFile:this.document.nombre
    }
    
    if(this.document.nombre == ''){
      this.notificationService.message(
        NotificationType.INFO,
        'Debes capturar nombre del documento',
        '',
        null,
        null,
        null);
      return;
    }

    if(this.uploadedFiles==null || this.uploadedFiles.length==0 ){
      this.notificationService.message(
        NotificationType.INFO,
        'Debes agregar un documento',
        '',
        null,
        null,
        null);
      return;
    }

    formData.append('Filepdf',this.uploadedFiles[0], this.uploadedFiles[0].name);
    formData.append('FileData', JSON.stringify(this.fileData));

    if (!this.validSize) {
        this.uploadFile(formData);
    }
   
  }

  handleAction($event: Action, item: any): void {
    
    console.log(item)
    const index = this.documentos.findIndex(x=>x.nombre == item.nombre);          
    console.log("Delete... " + index + " " + item._id);
    this.documentos.splice(index, 1);
    this.items = cloneDeep(this.documentos);

  }

  guardarPolitica(){    
            
  try {
    this.validaPoliticaNueva();
    this.createPoliticaToPost();

    this.politicasService.createPolicy(this.politicas)
      .subscribe((response: any)=>{  
        console.log(response)
  
        this.notificationService.message(
          NotificationType.SUCCESS,
          'Política guardada de forma correcta',
          '',
          null,
          null,
          null);
    

  },error=>{
    console.log(error);
    this.notificationService.message(
      NotificationType.SUCCESS,
      "tenemos algo de dificultades,  Error:"+error.statusText+error.status,
      '',
      null,
      null,
      null);
    
    
  });
    console.log(this.politicas);
    console.log(this.documentos);    
  } catch (error) {
    alert(error);
  }
    
  }

  validaPoliticaNueva(){
    
    if(this.politicas.nombre == "" || this.politicas.nombre == undefined){
      this.validator.nombre == "Campo Requrido";  
    }

  }

  initValidator(){
    this.validator = {
      codigo : "",
      nombre : ""
    }
  }

  createPoliticaToPost(){
    let dateAux = this.createDatePolitica(this.politicasForm.value.lastUpdated);
    const areasInvolucradas= this.createAreasInvolucradas(this.politicasForm.value.areasInvolucradas);

    this.politicas.apartado = this.politicasForm.value.apartado;
    this.politicas.codigo = this.politicasForm.value.codigo;
    this.politicas.nombre = this.politicasForm.value.nombre;
    //this.politicas.descripcion = this.politicasForm.value.descripcion;
    this.politicas.lastUpdated = dateAux;
    this.politicas.areaResponsable = this.politicasForm.value.areaResponsable;
    this.politicas.areasInvolucradas = areasInvolucradas;
    this.politicas.documentos = this.documentos;
  }

  createDatePolitica(fecha){
    var dateAux = new Date(fecha.replace( /(\d{2})\/(\d{2})\/(\d{4})/, "$2/$1/$3"));
    
    return fecha.replace( /(\d{2})\/(\d{2})\/(\d{4})/, "$2/$1/$3")
  }

  createAreasInvolucradas(areas:any){
    let arrayAreas = [];
    areas.forEach(element => {
      arrayAreas.push(element.item_text);   
    });
    return arrayAreas;
  }

  ngOnInit() {
    this.politicasForm.value.codigo="VTN-POL-";
    this.configAreas();
    this.createDocument();
    this.obtainAreaAndValidateData();
    this.configTable();

    //Se va a utilizar  cuando el campo de de area  areas involucradas se puda guardar
    /*this.selectedItems = [
      { item_id: 3, item_text: 'Pune' },
      { item_id: 4, item_text: 'Navsari' }
    ];*/ 
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Secionar todo',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    }as IDropdownSettings;
  }

  configTable(){

    this.actionConfig = {
      primaryActions: [{
        id: 'editaArea',
        title: 'Eliminar',
        tooltip: 'Eliminar documento'
      }],
      moreActions: [],
    } as ActionConfig;


    this.listConfig = {
      dblClick: false,
      multiSelect: false,
      selectItems: false,
      selectionMatchProp: 'name',
      showCheckbox: false,
      useExpandItems: false
    } as ListConfig;


    this.filterConfig = {
      fields: [{
        id: 'nombre',
        title: 'nombre',
        placeholder: 'Filter by nombre',
        type: FilterType.TEXT
      }, {
        id: 'codigo',
        title: 'codigo',
        placeholder: 'Filter by codigo',
        type: FilterType.TEXT
      }, ] as FilterField[],
     // resultsCount: this.items.length,
      appliedFilters: []
    } as FilterConfig;

  }

  configAreas(){
    this.areasPoliticas = [
      'Institucional'
    ]
    this.areasMOVinte = [
      'ADC',
      'Capital Humano',
      'CEDECO',
      'Comercialización',
      'Comunicación corporativa',
      'Contraloría',
      'Finanzas',
      'Fundación',
      'Integración',
      'IPO',
      'Jurídico',
      'Mercadotecnia/Diseño',
      'Planeación F. Inmobiliaria',
      'Planeación Financiera',
      'Tesorería',
      'Postventa',
      'TI',
      'Titulación',
      'Vinte diseño'
    ]
    this.areasMOEmobel = [
      'Capital Humano',
      'Contraloría',
      'Logística',
      'Mercadotecnia',
      'Operaciones',
      'Ventas'
    ];
    this.listAreasAll=[
      'ADC',
	    'CEDECO',
	    'Comercialización',
      'Comunicación corporativa',       
	    'Conectha',
      'Contraloria',                
      'DACH',
      'Capital Humano',                
      'EMOBEL',
      'Finanzas',
      'Fiscal',
      'Institucional',
      'Integración',
      'Interplan',
      'IPO',
      'Jurídico',
      'Mercadotecnia',
      'Operación',
      'Planeación financiera',
      'Planeación financiera inmobiliaria',                      
      'Post Venta',
      'Tesorería',
      'TI',
      'Titulación',
      'Vinte diseño',

    ];

    this.listAreasAll.forEach((element, index )=> {
      const item = {
         item_id: index + 1, 
         item_text: element   
      }
      this.dropdownList.push(item);
    });

    this.clasificacion = this.clasificacionPolitica;
    this.listAreas = this.listAreasAll;
    
  }
 
  textDate(fechaToShow){
    const date = moment.utc(fechaToShow);
    return date.format('DD/MM/YYYY');
   //return (fechaToShow.getDate() + 1) + '/' + (fechaToShow.getMonth() +1) + '/' + fechaToShow.getFullYear();
  }

  obtainAreaAndValidateData(){
    this.politicaEdit = this.router.snapshot.queryParams["idPolitica"];
   // console.log(this.politicaEdit);
    
    this.activeEdit = (this.politicaEdit != '' && this.politicaEdit != undefined);
    if(this.activeEdit){
      this.politicasService.getPoliticas(this.politicaEdit)
        .subscribe(x=>{
          //console.log(x);
          var pol = x[0];
          //let fechaToShow = new Date(pol.lastUpdated);
          let txtDate  = pol.lastUpdated ? this.textDate(pol.lastUpdated) : '';
          this.documentos = pol.documentos;
          this.items = cloneDeep(pol.documentos);
          this.handleAreasInvolucradas(pol.areasInvolucradas);

          if (pol.nombre =="Código de ética" || pol.nombre =="Decálogo de integridad") {
            this.politicasForm.get('areasInvolucradas').clearValidators();
          }

          this.politicasForm.setValue({
            codigo: pol.codigo, 
            nombre: pol.nombre,
            //descripcion: pol.descripcion,
            lastUpdated: txtDate,
            areaResponsable: pol.areaResponsable==undefined?'':pol.areaResponsable,
            apartado: pol.apartado == undefined ? '' :pol.apartado,
            areasInvolucradas:  this.selectedItems
          });
          switch (pol.apartado) {
            case 'Políticas Institucionales':
            case 'Políticas Fundación VINTE':     
            case 'Políticas XANTE':     
              this.clasificacion = this.clasificacionPolitica;
              this.listAreas = this.areasPoliticas;
              break;
            case 'Manuales de operación VINTE':
              this.clasificacion = this.clasificacionManual;
              this.listAreas = this.areasMOVinte;
              break;
            case 'Manuales de operación EMOBEL':
            case 'Guías VINTE':
              this.clasificacion = this.clasificacionManual;
              this.listAreas = this.areasMOEmobel;   
              break;
            default:
              this.clasificacion = this.clasificacionPolitica;
              this.listAreas = this.listAreasAll;
  
              break;
          }
        })
    }     
    
  }

  handleAreasInvolucradas(areas: any): void{
    const arrayAreas = [];
    areas.forEach((element) => {
      const items = this.dropdownList.filter(item=> item.item_text == element);
      if (items.length > 0){
        const areas ={
          item_id: items[0].item_id,
          item_text: element
        }
        arrayAreas.push(areas);
      } 
    });
    this.selectedItems = arrayAreas;
  }

  handleViewingChange(){}

  createDocument(){
    this.document = {
      tipoDocto:"",
      nombre: "",
      link: "",
      clasificación:""
    }
  }

  onFileChange(e){
    let fileSize=52428800;
    (e.target.files[0].size>fileSize) ? this.validSize=true : this.validSize=false;
   
    //console.log("FILE: ", e.target.files[0]);
    this.uploadedFiles=e.target.files;
    this.file=e.target.files[0].name;

    this.checkFile();
  }

  uploadFile(formData){
    this.SpinnerState=true;
    this.politicasService.uploadFile(formData)
    .subscribe((response: any)=>{  
      //console.log("Response: ",response.URL)
      if (response.upload=='success') {
        
          this.document.clasificación = this.opcionSeleccionado;

          let URL= `https://politicasvintedocuments.blob.core.windows.net/politicasvinte/${this.politicasForm.value.nombre}/${this.uploadedFiles[0].name}`
          this.document.link=URL;
          this.documentos.push(this.document);
          this.createDocument();
          this.items = cloneDeep(this.documentos);

          this.notificationService.message(
            NotificationType.SUCCESS,
            'Documento guardado de forma correcta',
            '',
            null,
            null,
            null);
          this.SpinnerState=false;

      } else {
          this.notificationService.message(
            NotificationType.WARNING,
            `Tenemos algo de dificultades.`,
            '',
            null,
            null,
            null);
            this.SpinnerState=false; 
        }
     
      },error=>{
        console.log(error);
        this.notificationService.message(
          NotificationType.WARNING,
          `Tenemos algo de dificultades,  Error: ${error.statusText}, Code: ${error.status}`,
          '',
          null,
          null,
          null); 
          this.SpinnerState=false; 
      });
  }

  checkFile(){
    //console.log("File Name: ", this.file);
    this.fileName=this.file;
  }

  //Funciones multiselect
  onItemSelect(item: any) {
    console.log(item);
  }
  onSelectAll(items: any) {
    console.log(items);
  }
  
}
