import { Component, OnInit } from '@angular/core';
import { VinteService } from '../vinteServices/vinte.service';
import { NotificationService } from '../notification/notification-service/notification.service';
import { NotificationType } from '../notification/notification-type';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';


@Component({
  selector: 'app-metricas-portal',
  templateUrl: './metricas-portal.component.html',
  styleUrls: ['./metricas-portal.component.css']
})
export class MetricasPortalComponent implements OnInit {
  DATE_STATE:boolean=false;
  VALID_DATE_STATE:Boolean=false;
  SPINNERE_STATE:boolean=false;
  STATUS_STATE:boolean=true;
  SPINNER_TIME_STATE:boolean=false;
  STATUS_TIME_STATE:boolean=true;

  notifications: any=[];
  dataMet:any;
  dataTimeMet:any;
  dateForm: FormGroup;

  card1D: any = {
    name: "",
    total: 0,
  };

  card7D:any = {
    name: "",
    total: 0,
  };

  card15D: any = {
    name: "",
    total: 0,
  };

  cardTime:any={
    name:"",
    total:0
  }


  /**
   * The default constructor
   */
  constructor(
    private  politicasService: VinteService,
    public notificationService: NotificationService,
    public formBuilder: FormBuilder,
    ){
      this.notifications = notificationService.getNotifications();
      this.dateForm = this.formBuilder.group({
        startDate: ['', [Validators.required]],
        endDate: ['', [Validators.required]],
      });
    }

  /**
   * Setup any component configuration upon initialization
   */
  ngOnInit(): void {
    this.metricas();
  }

  metricas(){
    this.SPINNERE_STATE=true;
    this.politicasService.getMetricasPortal()
    .subscribe(res=>{
      console.log("Response Metrica:", res);
      this.SPINNERE_STATE=false;
      this.STATUS_STATE=true;
      this.dataMet=res;
      this.card1D.total=this.dataMet.last24hrs;
      this.card7D.total=this.dataMet.last7Days;
      this.card15D.total=this.dataMet.last15Days;

    },error=>{
      console.log(error);
      this.notificationService.message(
        NotificationType.WARNING,
        `Tenemos algo de dificultades,  Error: ${error.statusText}, Code: ${error.status}`,
        '',
        null,
        null,
        null); 
        this.SPINNERE_STATE=false;
        this.STATUS_STATE=false; 
    });
  }

  refresMetricas(){
    console.log("Entrandop Refresh");
    this.metricas();
  }
  
  timeMetricas(){

    console.log("Entrando time Metricas");
    this.SPINNER_TIME_STATE=true;
    //this.dataDate.startDate=

    this.politicasService.getTimeMetricasPortal(this.dateForm.value)
    .subscribe((res:any)=>{
      this.SPINNER_TIME_STATE=false;
      this.STATUS_TIME_STATE=true;
      console.log("Response TimeMetricas:", res);
      this.dataTimeMet=res;
      this.cardTime.total=this.dataTimeMet.visitsPerTime;

    },error=>{
      this.SPINNER_TIME_STATE=false;
      this.STATUS_TIME_STATE=false;
      console.log("[ERROR TIME-METRICAS]: ",error);
      this.notificationService.message(
        NotificationType.WARNING,
        `Tenemos algo de dificultades,  Error: ${error.statusText}, Code: ${error.status}`,
        '',
        null,
        null,
        null);

    });
  }


  dateChenge(e){
    let fechaIn=this.dateForm.value.startDate;
    let fechaFin=this.dateForm.value.endDate;
    console.log("Fecha Inicio: ", fechaIn);
    console.log("Fecha Fin: ", fechaFin);
    
    if (fechaIn!=='' && fechaFin!=='') {

      if (fechaIn<fechaFin) {
          this.DATE_STATE=false;
          this.VALID_DATE_STATE=true;
         
      } else {
          this.DATE_STATE=true;
          this.VALID_DATE_STATE=false;
           
      }
    }
  }
  
}
