import {
  Component,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import { cloneDeep } from 'lodash';
import { HomeService } from '../home/home.service';
import * as MicrosoftGraph from "@microsoft/microsoft-graph-types"
import { Subscription, Observable } from 'rxjs';

import { ListConfig } from 'patternfly-ng/list/basic-list/list-config';
//import { ListEvent } from 'patternfly-ng/list/list-event';
import { EmptyStateConfig } from 'patternfly-ng/empty-state/empty-state-config';
import { ActionConfig } from 'patternfly-ng/action/action-config';

import { VinteService } from '../vinteServices/vinte.service';
import { FilterType } from 'patternfly-ng/filter/filter-type';
import { FilterField } from 'patternfly-ng/filter/filter-field';
import { FilterConfig } from 'patternfly-ng/filter/filter-config';
import { Filter } from 'patternfly-ng/filter/filter';
import { FilterEvent } from 'patternfly-ng/filter/filter-event';

import {GroupsService } from '../area-vinte/service/group.service'
import {UsersService } from '../area-vinte/service/user.service'
import { switchMap, map } from 'rxjs/operators';
import {HttpService} from '../shared/http.service'
//import { tickStep } from 'd3';
import { Router } from '@angular/router';
import { _ } from 'underscore';
import { ActivatedRoute } from '@angular/router';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  me: MicrosoftGraph.User;
  subsGetMe: Subscription;
  subsGetUsers: Subscription;
  groupSelectedName:string;
  actionsText: string = 'hola  aqui';
  allItems: any[];
  emptyStateConfig: EmptyStateConfig;
  items: any[];
  itemsAvailable: boolean = true;
  listConfig: ListConfig;
  selectType: string = 'checkbox';
  Id:string;
  IdGroup:string;
  groupName:string;
  politicas:any={};
  filterConfig: FilterConfig;

  proseso:string;
  filtersText: string = '';

  //LOADING FLAGS
  nonPoliciesAccess:boolean = false;
  loading:boolean = true;
  loadedTable:boolean = false;
  loadingPoliticas:boolean = true;

  docs:any[];;
  groups:any[];

  nombrePolDayQueries: any[];
  codigoPolDayQueries: any[];
  areaPolDayQueries: any[];

  trackPoliticas:any={};

constructor( 
    //private homeService: HomeService, 
    private politicasService: VinteService,
    private groupService: GroupsService,
    //private userService: UsersService,
    private httpService: HttpService,
    private router: Router,
    //private snapshot:ActivatedRoute
) {}

    onSelect(doc:any,item:any):void{
      let Route="Profile";
      localStorage.setItem("Route",Route);
      this.trackEventPol(doc, item);
      
      this.router.navigate(['pdf'], { queryParams: { idDoc: doc.link, nombreDoc: doc.nombre,nobreItem:item.codigo,
                                                    nombrePol:item.descripcion, clasificacion: doc.clasificación} });
      //this.router.navigate(['pdf'], { queryParams:[doc]});
    }

 /**
  *    Metodo patra obtener todas las politicas que existen.
  **/
 getAllPlocies():void{
 
  this.httpService.getMe()
  .subscribe(x=>{
    this.me=x;
    //console.log(this.me);
  
   /* this.getUserGroups(this.me.id)
    .subscribe(y=>{   
      this.loading = false;
      let politica = y.groups.find(
        (el)=>{
          return el.displayName == "Politicas"; //Todos los usuarios
        }
    );
   
    if(politica == undefined){
      this.nonPoliciesAccess = true;
      this.nonPoliciesAccess = true;
    }else{      
      this.loadingPoliticas = true;
    }

    let id = politica.id;
    if (politica.displayName=="Politicas") {
      this.groupSelectedName="Políticas";
    }else{
      this.groupSelectedName = politica.displayName;
    }
 
    this.politicasService.getAreaByGroup(id)//id del grupo "6a8b878e-8693-41ce-8ac3-4f36b8f53308"
      .subscribe(politicas=>{
        this.loadedTable = true;
        this.loadingPoliticas = false;

        this.allItems = politicas[0].politicas;
        this.allItems.sort((a,b) => a.codigo.localeCompare(b.codigo));
        this.items = cloneDeep(this.allItems);  

        this.politicasQueries(this.items);

        this.items.forEach(function(element) {

          var documentos =element.documentos;
          documentos.sort((a,b) => a.clasificación.localeCompare(b.clasificación));

          var ordering = {}, // map for efficient lookup of sortIndex
          sortOrder = ['Política','Proceso','Formatos','Anexos','Manuales','Presentación y evaluación',''];
          for (var i=0; i<sortOrder.length; i++)
              ordering[sortOrder[i]] = i;
              documentos.sort( function(a, b) {
                return (ordering[a.clasificación] - ordering[b.clasificación]) || a.nombre.localeCompare(b.nombre);
              });
        });
      })
    });
*/
    this.politicasService.getPoliticasPorApartado('Políticas Institucionales')
    .subscribe((politicas: any)=>{
      //this.loading = false;
      this.loadedTable = true;
      this.loadingPoliticas = false;

      this.allItems = politicas.documentos;
      this.allItems.sort(function(a,b){
        return a.codigo >b.codigo?1:a.codigo <b.codigo?-1:0
      });
      
      this.items = cloneDeep(this.allItems);
      this.politicasQueries(this.items);

      this.items.forEach(element => {
          var documentos = element.documentos;
          documentos.sort(function(a,b){
            return a.clasificación >b.clasificación?1:a.clasificación <b.clasificación?-1:0
          });
          
          var ordering = {}, // map for efficient lookup of sortIndex
          sortOrder = ['Política','Manual de operación','Proceso','Formatos','Anexos','Guía','Presentación',
            'Manuales','Presentación y evaluación',''];

          for (var i=0; i<sortOrder.length; i++) {
            ordering[sortOrder[i]] = i;
            documentos.sort( function(a, b) {
              return (ordering[a.clasificación] - ordering[b.clasificación]) || a.nombre.localeCompare(b.nombre);
            });
            
          }
      });  
    },error=>{
      this.loadingPoliticas = false;
      console.log(error);
      alert("Tenemos algo de dificultades,  Error:"+error.statusText+error.status);
    });

  });
}

  politicasQueries(items:any):void{
    let dataNamePol=[];
    let dataCodigoPol=[];
    let dataAreaPol=[];

    items.forEach((element, index) => {
      
      if (element.nombre) {
          let namePol={
            id:index+1,
            value:element.nombre
          }
          dataNamePol.push(namePol); 
      }
      if (element.codigo) {
        let namePol={
          id:index+1,
          value:element.codigo
        }
        dataCodigoPol.push(namePol); 
      }
      if (element.areaResponsable) {
        let namePol={
          id:index+1,
          value:element.areaResponsable
        }
        dataAreaPol.push(namePol); 
      }
    });
    this.nombrePolDayQueries=cloneDeep(dataNamePol);
    this.codigoPolDayQueries=cloneDeep(dataCodigoPol);
    this.areaPolDayQueries=cloneDeep(dataAreaPol);
    
    this.filterConfig.fields[0].queries=cloneDeep(dataNamePol);
    this.filterConfig.fields[1].queries=cloneDeep(dataCodigoPol);
    this.filterConfig.fields[2].queries=cloneDeep(dataAreaPol);
  }


ngOnInit(): void {

  this.filterConfig = {
        fields: [{
          id: 'nombre',
          title: 'Nombre',
          placeholder: 'Teclear y elegir',
          type: FilterType.TYPEAHEAD,
          queries: [],
        }, {
          id: 'codigo',
          title: 'Código',
          placeholder: 'Teclear y elegir',
          type: FilterType.TYPEAHEAD,
          queries: [],
        },
        {
          id: 'area',
          title: 'Área',
          placeholder: 'Teclear y elegir',
          type: FilterType.TYPEAHEAD,
          queries: [],
        } 
      ] as FilterField[],
        resultsCount: 0,
        appliedFilters: []
      } as FilterConfig;

  this.getAllPlocies();
  this.initConfigTable();
    
}
initConfigTable():void{
  this.emptyStateConfig = {
    actions: {
      primaryActions: []
    } as ActionConfig,
    iconStyleClass: 'pficon-warning-triangle-o',
    title: '¡Lo sentimos! No se encontró ninguna política',
    info: 'Comuníquese con el administrador o intente mas tarde.'
  } as EmptyStateConfig;

  this.listConfig = {
    dblClick: false,
    emptyStateConfig: this.emptyStateConfig,
    multiSelect: false,
    selectItems: false,
    selectionMatchProp: 'name',
    showCheckbox: false,
    showRadioButton: false,
    useExpandItems: true
  } as ListConfig;
}

applyFilters(filters: Filter[]): void {
  this.items = [];
  if (filters && filters.length > 0) {
    this.allItems.forEach((item) => {
      if (this.matchesFilters(item, filters)) {
        this.items.push(item);
      }
    });
  } else {
    this.items = this.allItems;
  }
  this.filterConfig.resultsCount = this.items.length;
}

getUserGroups(userId): Observable<IUserGroups> {

    return this.groupService.getGroups().pipe(switchMap(grs => {
      return this.groupService.getGroupsByUser(userId).pipe(map(g => {

        let ugroups = g.map(e => {          
          let io = grs.find(r => r.id === e.id);
          return io;
        });

        return { user: null, groups: ugroups };
      }))
    })) 
}

filterChanged($event: FilterEvent): void {
  this.filtersText = '';
  $event.appliedFilters.forEach((filter) => {
    this.filtersText += filter.field.title + ' : ' + filter.value + '\n';
  });
  this.applyFilters($event.appliedFilters);
  this.filterFieldSelected($event);
}

matchesFilter(item: any, filter: Filter): boolean {
  let match = true;
  if (filter.field.id === 'nombre') {
    match = item.nombre === filter.value;
  } else if (filter.field.id === 'codigo') {
    match = item.codigo === filter.value;
  } else if (filter.field.id === 'area') {
    match = item.areaResponsable === filter.value;
  }
  return match;
}

matchesFilters(item: any, filters: Filter[]): boolean {
  let matches = true;
  filters.forEach((filter) => {
    if (!this.matchesFilter(item, filter)) {
      matches = false;
      return matches;
    }
  });
  return matches;
}

  // Filter queries for type ahead
  filterQueries($event: FilterEvent) {

      const index = (this.filterConfig.fields as any).findIndex((i: any) => i.id === $event.field.id);
      let val = $event.value.trim();
       //console.log("FILTER QUERIS:", this.filterConfig.fields[index].id);
      switch (this.filterConfig.fields[index].id) {
        case "nombre":
            //console.log("FILTER QUERIS4: ", this.filterConfig.fields[index].queries);
            let data1= this.nombrePolDayQueries.filter((item: any) => {
              if (item.value) {
                return (item.value.toLowerCase().indexOf(val.toLowerCase()) > -1);
              } else {
                return true;
              }
            })
            this.filterConfig.fields[index].queries = data1;
            //console.log("FILTER QUERIS5: ", this.filterConfig.fields[index].queries);
          break;
        case "codigo":
            //console.log("FILTER QUERIS4: ", this.filterConfig.fields[index].queries);
            let data2= this.codigoPolDayQueries.filter((item: any) => {
              if (item.value) {
                return (item.value.toLowerCase().indexOf(val.toLowerCase()) > -1);
              } else {
                return true;
              }
            })
            this.filterConfig.fields[index].queries = data2;
            //console.log("FILTER QUERIS5: ", this.filterConfig.fields[index].queries);
          break;
        case "area":
           //console.log("FILTER QUERIS4: ", this.filterConfig.fields[index].queries);
            let data3= this.areaPolDayQueries.filter((item: any) => {
              if (item.value) {
                return (item.value.toLowerCase().indexOf(val.toLowerCase()) > -1);
              } else {
                return true;
              }
            })
            this.filterConfig.fields[index].queries = data3;
            //console.log("FILTER QUERIS5: ", this.filterConfig.fields[index].queries);    
          break;  
        default:
          break;
      }
  }

     // Reset filtered queries
  filterFieldSelected($event: FilterEvent): void {
    this.filterConfig.fields.forEach((field) => {

      switch (field.id) {
        case "nombre":
          field.queries = this.nombrePolDayQueries;
          break;
        case "codigo":
          field.queries = this.codigoPolDayQueries;
          break;
        case "area":
          field.queries = this.areaPolDayQueries;
          break;
        default:
          break;
      }
    });
  }

  trackEventPol(dataDoc:any,dataPol:any):void{
    this.trackPoliticas={
      Usuario:this.me.displayName,
      Apartado: dataPol.apartado,
      Politica:dataPol.nombre,
      Codigo:dataPol.codigo,
      NombreDoc:dataDoc.nombre,
      ClasificacionDoc:dataDoc.clasificación
    }

    this.politicasService.trackEventPoliticas(this.trackPoliticas);
  }
  
}