import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
//import { map } from 'rxjs/operators';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
@Injectable({
  providedIn: 'root'
})
export class VinteService {
  appInsights: ApplicationInsights;
  //HOST_SERVICE = "https://0e276d9f.ngrok.io";
  HOST_SERVICE = "https://vintepoliticas.azurewebsites.net";
  API_ENDPOINT_POLICIES = this.HOST_SERVICE + '/api/politicas';
  API_ENDPOINT_POLICIES_MO = this.HOST_SERVICE + '/api/apartadosPoliticas/';
  API_ENDPOINT_AREAS = this.HOST_SERVICE + '/api/areasPolitica';
  API_ENDPOINT_FILE = this.HOST_SERVICE +'/api/uploadfile' ;//this.HOST_SERVICE + '/api/areasPolitica';
  API_ENDPOINT_METRICAS_PORTAL=this.HOST_SERVICE+'/api/metricas-portal'
  API_ENDPOINT_TIME_METRICAS_PORTAL=this.HOST_SERVICE+'/api/time-metricas-portal';
  API_ENDPOINT_TIME_METRICAS_POLITCAS=this.HOST_SERVICE+'/api/metricas-politicas';
  API_ENDPOINT_USERS_ADMIN = this.HOST_SERVICE + '/api/administrator/';

  constructor(private http: HttpClient) {
    this.appInsights= new ApplicationInsights({ config: {
      instrumentationKey: '07146836-91bf-451f-a61c-fb1122f9f23e'
      /* ...Other Configuration Options... */
    } });
    this.appInsights.loadAppInsights();
    this.appInsights.trackPageView(); 
   }


  public getPoliticas(idPolitica) {
    
    let endpoint = this.API_ENDPOINT_POLICIES;
    if(idPolitica != null && idPolitica != ''){
      endpoint += ("?codigo=" + idPolitica);
    }
    console.log(idPolitica);    
    console.log(endpoint);
    return this.http.get(endpoint);
  }

  public createPolicy(objPolicy) {
    console.log("Politica submited")
    console.log(objPolicy);
    const headers= new HttpHeaders({"Content-Type":"application/json"});
    return this.http.post(this.API_ENDPOINT_POLICIES, JSON.stringify(objPolicy),{headers:headers});
  }

  public getAreaByGroup(idGroup) {
    return this.http.get(this.API_ENDPOINT_AREAS + "?areaId=" + idGroup);
  }

  public saveAreaPolicies(objectArea) {
    const headers= new HttpHeaders({"Content-Type":"application/json"});
    return this.http.post(this.API_ENDPOINT_AREAS, JSON.stringify(objectArea), {headers:headers});
  }

  public uploadFile(objFile){
    //const headers= new HttpHeaders({"Content-Type":"application/json"});
    return this.http.post(this.API_ENDPOINT_FILE, objFile);
  }

  public getMetricasPortal(){
    return this.http.get(this.API_ENDPOINT_METRICAS_PORTAL);
  }

  public getTimeMetricasPortal(dataMet){
    const headers= new HttpHeaders({"Content-Type":"application/json"});
    return this.http.post(this.API_ENDPOINT_TIME_METRICAS_PORTAL, JSON.stringify(dataMet),{headers:headers});
  }

  public getTimeMetricasPol(objTimeMet){
    const headers= new HttpHeaders({"Content-Type":"application/json"});
    return this.http.post(this.API_ENDPOINT_TIME_METRICAS_POLITCAS, JSON.stringify(objTimeMet),{headers:headers});

  }

  /**
   * tracEventPoliticas
   */
  public trackEventPoliticas(trackEvent:any) {
    //console.log("TRACKEVENT: ", trackEvent);
    this.appInsights.trackEvent({ name: "POLITICAS_VIEW_TEST", },trackEvent);
    
  }

  public getPoliticasPorApartado(apartado) {
    
    const endpoint = this.API_ENDPOINT_POLICIES_MO+apartado;
  
    return this.http.get(endpoint);
  }

  public userAdmin(email: string){
    const url = this.API_ENDPOINT_USERS_ADMIN+email;
    return this.http.get(url);
  }


}
