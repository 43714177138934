import { HttpService } from '../../shared/http.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable(
    {
        providedIn: 'root'
      }
)
export class GroupsService {

    constructor(public sharedService: HttpService) { }

    public getTest(){
        console.log('Testing...')
    }

    public getGroups(): Observable<IGroup[]> {
        
        return new Observable(obs => {
            let data = [];

            let ajax = (url: string) => {
                this.sharedService.client.api(url).get().then((res: any) => {
                    let urlnext = res['@odata.nextLink'];
                    data = [...data, ...res.value];

                    if (urlnext) {                        
                        ajax(urlnext);
                    } else {                        
                        obs.next(data);
                        obs.complete();
                    }
                });
            }
            ajax('groups');
        });
    }

    
    public getGroupsByUser(id: string): Observable<IGroup[]> {
        return new Observable(obs => {
            this.sharedService.client.api(`users/${id}/getMemberGroups`)
                .post({
                    "securityEnabledOnly": false
                }).then(res => {
                    if (res.value) {
                        let result = res.value.map(e => {
                            return { id: e }
                        });
                        obs.next(result);
                    } else obs.next([]);
                    obs.complete();
                });
        });
    }

    public addMember(idGrupo: string, idUser: string): Observable<boolean> {
        return new Observable(obs => {
            this.sharedService.client.api(`groups/${idGrupo}/members/$ref`)
                .post({
                    "@odata.id": `https://graph.microsoft.com/v1.0/users/${idUser}`
                })
                .then(_ => {
                    obs.next(true);
                    obs.complete();
                });
        });
    }

    public delMember(idGrupo: string, idUser: string): Observable<boolean> {
        return new Observable(obs => {
            this.sharedService.client.api(`groups/${idGrupo}/members/${idUser}/$ref`)
                .delete()
                .then(_ => {
                    obs.next(true);
                    obs.complete();
                });
        });
    }
}
