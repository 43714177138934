import { Routes, RouterModule } from '@angular/router';
import { DashboardLayoutComponent } from './template/dashboard/dashboard';
import { PoliticasComponent } from './politicas/politicas.component';
//import { LoginTemplateComponent } from './template/login/logintemplate.component';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { AreaVinteComponent } from './area-vinte/area-vinte.component';
import { AreaPoliticaComponent } from './area-politica/area-politica.component';
import { ListBasicExampleComponent } from './list-basic-example/list-basic-example.component';
import { NuevaPoliticaComponent } from './nueva-politica/nueva-politica.component';
import { NuevaAreaComponent } from './nueva-area/nueva-area.component';
import { ProfileComponent } from './profile/profile.component';
import { PdfViewerComponent } from './pdf-viewer/pdf-viewer.component';
import { MisPoliticasComponent } from './mis-politicas/mis-politicas.component';
import { PoliticasViewComponent } from './politicas-view/politicas-view.component';
import { PdfConvenioComponent } from './pdf-convenio/pdf-convenio.component';
import { CodigoEticaComponent} from './codigoEtica/codigoEtica.componet';
import { MetricasPortalComponent } from './metricas-portal/metricas-portal.component';
import { MetricasPoliticasComponent } from './metricas-politicas/metricas-politicas.component';
import { ApartadoMoVinteComponent} from './apartado-mo-vinte/apartado-mo-vinte.component';
import { ApartadoMoEmobelComponent} from './apartado-mo-emobel/apartado-mo-emobel.component';
import { PoliticasFVComponent } from './politicas-fv/politicas-fv.component';
import { ApartadoXanteComponent} from './apartado-xante/apartado-xante.component';
import { DecalogoIntegridadVinteComponent } from './decalogo-integridad-vinte/decalogo-integridad-vinte.component';
import { ApartdoGuiasVinteComponent } from './apartdo-guias-vinte/apartdo-guias-vinte.component';

const appRoutes: Routes = [

    { 
        path: '', 
        component: LoginComponent,
        children: [    
          { path: '', component: PoliticasComponent }    
        ]
      },
      { 
        path: 'convenio', 
        component: PdfConvenioComponent
      },

    { 
        path: '', 
        component: DashboardLayoutComponent,
        children: [    
          { path: 'perfil', component: PoliticasComponent },
             {path:'area', component:  AreaVinteComponent},
             {path:'areaPoliticas', component:  AreaPoliticaComponent},
             {path:'list', component: ListBasicExampleComponent},
             {path:'politicas', component: PoliticasComponent,},
             {path:'nuevaPolitica', component: NuevaPoliticaComponent},
             {path:'nuevaArea', component: NuevaAreaComponent},
             {path:'profile', component: ProfileComponent},
             {path:'pdf', component: PdfViewerComponent},
             {path:'misPoliticas', component: MisPoliticasComponent},
             {path:'politicasView', component: PoliticasViewComponent},
             {path:'convenioInPortal',component:PdfConvenioComponent},
             {path:'codigoEtica', component:CodigoEticaComponent},
             {path:'metricas-portal', component:MetricasPortalComponent},
             {path:'metricas-consultas_por_politicas', component:MetricasPoliticasComponent},  
             {path:'mo-vinte', component:ApartadoMoVinteComponent},  
             {path:'mo-emobel', component:ApartadoMoEmobelComponent},  
             {path:'politicas-fv', component:PoliticasFVComponent},
             {path:'politicas-xante', component:ApartadoXanteComponent},
             {path:'decalogo-integridad-vinte', component:DecalogoIntegridadVinteComponent},
             {path:'guias-vinte', component:ApartdoGuiasVinteComponent}
        ]
      },
      { path: 'home', component: HomeComponent },
    //  {path:'pdf', component: PdfViewerComponent}
           
];

export const routing = RouterModule.forRoot(appRoutes);
