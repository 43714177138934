
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FilterType } from 'patternfly-ng/filter/filter-type';
import { FilterField } from 'patternfly-ng/filter/filter-field';
import { FilterConfig } from 'patternfly-ng/filter/filter-config';
import { Filter } from 'patternfly-ng/filter/filter';
import { FilterEvent } from 'patternfly-ng/filter/filter-event';
import { ListConfig } from 'patternfly-ng/list/basic-list/list-config';
import { EmptyStateConfig } from 'patternfly-ng/empty-state/empty-state-config';
//import { ActionConfig } from 'patternfly-ng/action/action-config';
import { VinteService } from '../vinteServices/vinte.service';
import { cloneDeep } from 'lodash';
import { Router } from '@angular/router';
import { HttpService } from '../shared/http.service'
import * as MicrosoftGraph from "@microsoft/microsoft-graph-types";

@Component({
  selector: 'app-politicas-fv',
  templateUrl: './politicas-fv.component.html',
  styleUrls: ['./politicas-fv.component.css']
})
export class PoliticasFVComponent implements OnInit {
  filterConfig: FilterConfig;
  filtersText: string = '';
  listConfig: ListConfig;
  emptyStateConfig: EmptyStateConfig;
  items: any[];
  allItems: any[];
  nombrePolDayQueries: any[];
  codigoPolDayQueries: any[];
  areaPolDayQueries: any[];
  spinnerState:boolean=true;
  trackPoliticas:any={};
  me: MicrosoftGraph.User;

  constructor(
    private politicasService: VinteService,
    private router: Router,
    private httpService: HttpService,
  ) { }

  ngOnInit() {
    this.filterConfig = {
      fields: [
        {
          id: 'nombre',
          title: 'Nombre',
          placeholder: 'Teclear y elegir',
          type: FilterType.TYPEAHEAD,
          queries: [],
        
        }, {
          id: 'codigo',
          title: 'Código',
          placeholder: 'Teclear y elegir',
          type: FilterType.TYPEAHEAD,
          queries: [],
        },
        {
          id: 'area',
          title: 'Área',
          placeholder: 'Teclear y elegir',
          type: FilterType.TYPEAHEAD,
          queries: [],
        }
      ] as FilterField[],
      resultsCount:0,
      appliedFilters: [],
    } as FilterConfig;

    this.emptyStateConfig = {
      iconStyleClass: 'pficon-warning-triangle-o',
      title: '¡Lo sentimos! No se encontró ninguna política',
      info: 'Comuníquese con el administrador o intente mas tarde.'
    } as EmptyStateConfig;

    this.listConfig = {
      dblClick: false,
      emptyStateConfig: this.emptyStateConfig,
      multiSelect: false,
      selectItems: false,
      selectionMatchProp: 'name',
      showCheckbox: false,
      useExpandItems: true
    } as ListConfig;

    this.getAllPolicies();
    this.getUser(); 

  }

  getAllPolicies():void{
    this.politicasService.getPoliticasPorApartado('Políticas Fundación VINTE')
    .subscribe((politicas: any)=>{
      this.allItems = politicas.documentos;
      this.allItems.sort(function(a,b){
        return a.codigo >b.codigo?1:a.codigo <b.codigo?-1:0
      });
      
      this.items = cloneDeep(this.allItems);
      //this.allItems = cloneDeep(allItems);
      this.politicasQueries(this.items);

      this.items.forEach(element => {
          var documentos = element.documentos;
          documentos.sort(function(a,b){
            return a.clasificación >b.clasificación?1:a.clasificación <b.clasificación?-1:0
          });
          
          var ordering = {}, // map for efficient lookup of sortIndex
          sortOrder = ['Política','Manual de operación','Proceso','Formatos','Anexos','Manuales','Presentación y evaluación',''];

          for (var i=0; i<sortOrder.length; i++) {
            ordering[sortOrder[i]] = i;
            documentos.sort( function(a, b) {
              return (ordering[a.clasificación] - ordering[b.clasificación]) || a.nombre.localeCompare(b.nombre);
            });
            
          }
      });
      this.spinnerState=false;    
    },error=>{
      this.spinnerState=false;
      console.log(error);
      alert("tenemos algo de dificultades,  Error:"+error.statusText+error.status);
    });
  
  }

  onSelect(doc:any,item:any):void{
    const Route="Politicas-FV";
    localStorage.setItem("Route",Route);
    this.trackEventPol(doc, item);  
    this.router.navigate(['pdf'], { queryParams: { idDoc: doc.link, nombreDoc: doc.nombre,nobreItem:item.codigo,
                                                  nombrePol:item.descripcion, clasificacion: doc.clasificación} });
  }

  politicasQueries(items:any):void{
    let dataNamePol=[];
    let dataCodigoPol=[];
    let dataAreaPol=[];

    items.forEach((element, index) => {
      
      if (element.nombre) {
          let namePol={
            id:index+1,
            value:element.nombre
          }
          dataNamePol.push(namePol); 
      }
      if (element.codigo) {
          let namePol={
          id:index+1,
          value:element.codigo
        }
        dataCodigoPol.push(namePol); 
      }
      if (element.areaResponsable) {
        let namePol={
          id:index+1,
          value:element.areaResponsable
        }
        dataAreaPol.push(namePol); 
      }

    });

    this.nombrePolDayQueries=cloneDeep(dataNamePol);
    this.codigoPolDayQueries=cloneDeep(dataCodigoPol);
    this.areaPolDayQueries=cloneDeep(dataAreaPol);
    
    this.filterConfig.fields[0].queries=cloneDeep(dataNamePol);
    this.filterConfig.fields[1].queries=cloneDeep(dataCodigoPol);
    this.filterConfig.fields[2].queries=cloneDeep(dataAreaPol);

  }

  filterChanged($event: FilterEvent): void {
    this.filtersText = '';  
    $event.appliedFilters.forEach((filter) => {
      this.filtersText += filter.field.title + ' : ' + filter.value + '\n';
    });
    this.applyFilters($event.appliedFilters);
    this.filterFieldSelected($event);
  }

  applyFilters(filters: Filter[]): void {
    this.items = [];

    if (filters && filters.length > 0) {
      this.allItems.forEach((item) => {
        if (this.matchesFilters(item, filters)) {
          this.items.push(item);
        }
      });
    } else {
      this.items = this.allItems;
    }
    this.filterConfig.resultsCount = this.items.length;
  }

  matchesFilters(item: any, filters: Filter[]): boolean {
    let matches = true;
    filters.forEach((filter) => {
      if (!this.matchesFilter(item, filter)) {
        matches = false;
        return matches;
      }
    });
    return matches;
  }


  matchesFilter(item: any, filter: Filter): boolean {
    let match = true;
    if (filter.field.id === 'nombre') {
      match = item.nombre === filter.value;
    } else if (filter.field.id === 'codigo') {
      //match = item.codigo.match(re) !== null;
      match = item.codigo === filter.value;
    } else if (filter.field.id === 'area') {
      match = item.areaResponsable === filter.value;
    }
    return match;
  }

  // Reset filtered queries
  filterFieldSelected($event: FilterEvent): void {
    this.filterConfig.fields.forEach((field) => {

      switch (field.id) {
        case "nombre":
          field.queries = this.nombrePolDayQueries;
          break;
        case "codigo":
          field.queries = this.codigoPolDayQueries;
          break;
        case "area":
          field.queries = this.areaPolDayQueries;
          break;
      
        default:
          break;
      }
     
    });
  }

  // Filter queries for type ahead
  filterQueries($event: FilterEvent) {
    const index = (this.filterConfig.fields as any).findIndex((i: any) => i.id === $event.field.id);
    let val = $event.value.trim();

    switch (this.filterConfig.fields[index].id) {
      case "nombre":
          let data1= this.nombrePolDayQueries.filter((item: any) => {
            if (item.value) {
              return (item.value.toLowerCase().indexOf(val.toLowerCase()) > -1);
            } else {
              return true;
            }
          })
          this.filterConfig.fields[index].queries = data1;
        
        break;
      case "codigo":
          let data2= this.codigoPolDayQueries.filter((item: any) => {
            if (item.value) {
              return (item.value.toLowerCase().indexOf(val.toLowerCase()) > -1);
            } else {
              return true;
            }
          })
          this.filterConfig.fields[index].queries = data2;
        
        break;
      case "area":
          console.log("FILTER QUERIS4: ", this.filterConfig.fields[index].queries);
          let data3= this.areaPolDayQueries.filter((item: any) => {
            if (item.value) {
              return (item.value.toLowerCase().indexOf(val.toLowerCase()) > -1);
            } else {
              return true;
            }
          })
          this.filterConfig.fields[index].queries = data3;
        
        break;
    
      default:
        break;
    }
  }

  getUser():void{
    this.httpService.getMe()
    .subscribe( me =>{
      this.me=me;
    })

  }

  trackEventPol(dataDoc:any,dataPol:any):void{
    this.trackPoliticas={
      Usuario:this.me.displayName,
      Apartado: dataPol.apartado,
      Politica:dataPol.nombre,
      Codigo:dataPol.codigo,
      NombreDoc:dataDoc.nombre,
      ClasificacionDoc:dataDoc.clasificación
    }

    this.politicasService.trackEventPoliticas(this.trackPoliticas);
  }
}

