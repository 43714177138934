import {
  Component,
  OnInit,
  TemplateRef,
  ViewEncapsulation
} from '@angular/core';
import { cloneDeep } from 'lodash';

import { Action } from 'patternfly-ng/action/action';
import { ListConfig } from 'patternfly-ng/list/basic-list/list-config';
import { ListEvent } from 'patternfly-ng/list/list-event';
import { EmptyStateConfig } from 'patternfly-ng/empty-state/empty-state-config';
import { ActionConfig } from 'patternfly-ng/action/action-config';

import { VinteService } from '../vinteServices/vinte.service';
import { FilterType } from 'patternfly-ng/filter/filter-type';
import { FilterField } from 'patternfly-ng/filter/filter-field';
import { FilterConfig } from 'patternfly-ng/filter/filter-config';
import { Filter } from 'patternfly-ng/filter/filter';
import { FilterEvent } from 'patternfly-ng/filter/filter-event';
import { Router } from '@angular/router';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-politicas',
  templateUrl: './politicas.component.html',
  styleUrls: ['./politicas.component.css']
})
export class PoliticasComponent implements OnInit {

  actionsText: string = 'hola  aqui';
  allItems: any[];
  emptyStateConfig: EmptyStateConfig;
  items: any[];
  itemsAvailable: boolean = true;
  listConfig: ListConfig;
  selectType: string = 'checkbox';
  actionConfig: ActionConfig;

  politicas:any={};
  filterConfig: FilterConfig;
  nombrePolDayQueries: any[];
  codigoPolDayQueries: any[];
  areaPolDayQueries: any[];
  apartadoPolDayQueries: any[];

  filtersText: string = '';

  constructor(
    private politicasService: VinteService,
    private router: Router) {

    
  }

 
  onSelect(doc:any,item:any):void{
    this.router.navigate(['pdf'], { queryParams: { idDoc: doc.link, nombreDoc: doc.nombre ,nobreItem:item.codigo,
                                                   nombrePol:item.descripcion, clasificacion: doc.clasificación} });
    //this.router.navigate(['pdf'], { queryParams:[doc]});
  }

  /**
  *    Metodo patra obtener todas las politicas que existen.
  **/
  getAllPlocies():void{
    this.politicasService.getPoliticas(null)    //evento para obtener los datos vinte desde service     
    .subscribe((politicas: any)=>{  
      //debugger;    
      this.allItems=politicas//politicas;//pasar un object a un  arreglo
     
      //this.allItems.sort((a,b) => a.codigo.localeCompare(b.codigo));//oredenamiento
      this.allItems.sort(function(a,b){
        return a.codigo >b.codigo?1:a.codigo <b.codigo?-1:0
       });
      //console.log("===POLITICAS===");
      //console.log(this.allItems[0]);

      this.items = cloneDeep(this.allItems);
      
      this.politicasQueries(this.items);


      this.items.forEach(function(element) {

        var documentos =element.documentos;
        documentos.sort(function(a,b){
          return a.clasificación >b.clasificación?1:a.clasificación <b.clasificación?-1:0
         });
        //console.log("--DOCUMENTOS--");
        //console.log(documentos);

        var ordering = {}, // map for efficient lookup of sortIndex
        sortOrder = ['Política','Manual de operación','Proceso','Formatos','Anexos','Manuales', 'Guía','Presentación',
          'Presentación y evaluación',''];
        for (var i=0; i<sortOrder.length; i++)
            ordering[sortOrder[i]] = i;

            documentos.sort( function(a, b) {
              return (ordering[a.clasificación] - ordering[b.clasificación]) || a.nombre.localeCompare(b.nombre);
            });
    
      });

  },error=>{
    console.log(error);
    alert("tenemos algo de dificultades,  Error:"+error.statusText+error.status);
    //Swal.fire("tenemos algo de dificultades,  Error:"+error.status+'-'+error.statusText, this.titularAlerta, 'warning');
    
  });
  }

  politicasQueries(items:any):void{
    //let dat=[];
    let dataNamePol=[];
    let dataCodigoPol=[];
    let dataAreaPol=[];
    let dataApartadoPol=[];

    items.forEach((element, index) => {
      
      if (element.nombre) {
          let namePol={
            id:index+1,
            value:element.nombre
          }
          dataNamePol.push(namePol); 
      }
      if (element.codigo) {
        let namePol={
          id:index+1,
          value:element.codigo
        }
        dataCodigoPol.push(namePol); 
      }
      if (element.areaResponsable) {
        let namePol={
          id:index+1,
          value:element.areaResponsable
        }
        dataAreaPol.push(namePol); 
      }
      if (element.apartado) {
        let namePol={
          id:index+1,
          value:element.apartado
        }
        dataApartadoPol.push(namePol); 
      }

    });
    //this.weekDayQueries=cloneDeep(dat);
    this.nombrePolDayQueries=cloneDeep(dataNamePol);
    this.codigoPolDayQueries=cloneDeep(dataCodigoPol);
    this.areaPolDayQueries=cloneDeep(dataAreaPol);
    this.apartadoPolDayQueries=cloneDeep(dataApartadoPol);
    
    this.filterConfig.fields[0].queries=cloneDeep(dataNamePol);
    this.filterConfig.fields[1].queries=cloneDeep(dataCodigoPol);
    this.filterConfig.fields[2].queries=cloneDeep(dataAreaPol);
    this.filterConfig.fields[3].queries=cloneDeep(dataApartadoPol);
  }

  ngOnInit(): void {
    let Route="Politicas";
    localStorage.setItem("Route",Route);

    this.filterConfig = {
      fields: [{
        id: 'nombre',
        title: 'Nombre',
        placeholder: 'Teclear y elegir',
        type: FilterType.TYPEAHEAD,
        queries: [],
        
      }, {
        id: 'codigo',
        title: 'Código',
        placeholder: 'Teclear y elegir',
        type: FilterType.TYPEAHEAD,
        queries: [],
      },
      {
        id: 'area',
        title: 'Área',
        placeholder: 'Teclear y elegir',
        type: FilterType.TYPEAHEAD,
        queries: [],
      },
      {
        id: 'apartado',
        title: 'Apartado',
        placeholder: 'Teclear y elegir',
        type: FilterType.TYPEAHEAD,
        queries: [],
      }
    ] as FilterField[],
      resultsCount:0,
      appliedFilters: [],
    } as FilterConfig;
   

    this.getAllPlocies();
    

    this.actionConfig = {
      primaryActions: [{
        styleClass: 'button-primary',
        id: 'editaArea',
        title: 'Editar',
        tooltip: 'Edita Política'
      }],
      moreActions: [],
    } as ActionConfig;

    this.listConfig = {
      dblClick: false,
      multiSelect: false,
      selectItems: false,
      selectionMatchProp: 'name',
      showCheckbox: false,
      useExpandItems: true
    } as ListConfig;
    
  }

  handleAction($event: Action, item: any): void {    
    this.router.navigate(['nuevaPolitica'], { queryParams: { idPolitica: item.codigo } });    
  }

  initConfigTable():void{

    this.emptyStateConfig = {
      actions: {
        primaryActions: []
      } as ActionConfig,
      iconStyleClass: 'pficon-warning-triangle-o',
      title: 'No Existen Políticas creadas',
      info: 'Para acceder a la creación de una nueva politicas favor de hacer click en el boton "nueva politica"'
    } as EmptyStateConfig;

    this.listConfig = {
      dblClick: false,
      emptyStateConfig: this.emptyStateConfig,
      multiSelect: false,
      selectItems: false,
      selectionMatchProp: 'name',
      showCheckbox: false,
      showRadioButton: false,
      useExpandItems: true
    } as ListConfig;

  }


  applyFilters(filters: Filter[]): void {
    this.items = [];

    if (filters && filters.length > 0) {
      this.allItems.forEach((item) => {
        if (this.matchesFilters(item, filters)) {
          this.items.push(item);
          console.log("LENGTH ITEMS1:", this.items.length);
          //debugger;
    //this.filterConfig.resultsCount = this.items.length;
        }
      });
    } else {
      this.items = this.allItems;
      console.log("LENGTH ITEMS2:", this.items.length);
  
    }
    console.log("LENGTH ITEMS3:");
    this.filterConfig.resultsCount = this.items.length;
  }

  filterChanged($event: FilterEvent): void {
    this.filtersText = '';
    console.log("EVENT:", $event);
    
    $event.appliedFilters.forEach((filter) => {
      this.filtersText += filter.field.title + ' : ' + filter.value + '\n';
    });
    this.applyFilters($event.appliedFilters);
    this.filterFieldSelected($event);
  }


matchesFilter(item: any, filter: Filter): boolean {
  let match = true;
//  let re = new RegExp(filter.value, 'i');
  if (filter.field.id === 'nombre') {
    match = item.nombre === filter.value;
  } else if (filter.field.id === 'codigo') {
    //match = item.codigo.match(re) !== null;
    match = item.codigo === filter.value;
  } else if (filter.field.id === 'area') {
    match = item.areaResponsable === filter.value;
  } else if (filter.field.id === 'apartado') {
    match = item.apartado === filter.value;
  }
  return match;
}

matchesFilters(item: any, filters: Filter[]): boolean {
  let matches = true;
  filters.forEach((filter) => {
    if (!this.matchesFilter(item, filter)) {
      matches = false;
      return matches;
    }
  });
  return matches;
}



  ngDoCheck(): void {
  }



  handleSelectionChange($event: ListEvent): void {
    this.actionsText = $event.selectedItems.length + ' items selected\r\n' + this.actionsText;
  }

  handleClick($event: ListEvent): void {
    this.actionsText = $event.item.name + ' clicked\r\n' + this.actionsText;
  }

  handleDblClick($event: ListEvent): void {
    this.actionsText = $event.item.name + ' double clicked\r\n' + this.actionsText;
  }

  // Row selection

  updateItemsAvailable(): void {
    this.items = (this.itemsAvailable) ? cloneDeep(this.allItems) : [];
  }

  updateSelectionType(): void {
    if (this.selectType === 'checkbox') {
      this.listConfig.selectItems = false;
      this.listConfig.showCheckbox = true;
      this.listConfig.showRadioButton = false;
    } else if (this.selectType === 'radio') {
      this.listConfig.selectItems = false;
      this.listConfig.showCheckbox = false;
      this.listConfig.showRadioButton = true;
    } else if (this.selectType === 'row') {
      this.listConfig.selectItems = true;
      this.listConfig.showCheckbox = false;
      this.listConfig.showRadioButton = false;
    } else {
      this.listConfig.selectItems = false;
      this.listConfig.showCheckbox = false;
      this.listConfig.showRadioButton = false;
    }
  }


    // Filter queries for type ahead
  filterQueries($event: FilterEvent) {

      const index = (this.filterConfig.fields as any).findIndex((i: any) => i.id === $event.field.id);
      let val = $event.value.trim();
       //console.log("FILTER QUERIS:", this.filterConfig.fields[index].id);

      switch (this.filterConfig.fields[index].id) {
        case "nombre":
            //console.log("FILTER QUERIS4: ", this.filterConfig.fields[index].queries);
            let data1= this.nombrePolDayQueries.filter((item: any) => {
              if (item.value) {
                return (item.value.toLowerCase().indexOf(val.toLowerCase()) > -1);
              } else {
                return true;
              }
            })
            this.filterConfig.fields[index].queries = data1;
            //console.log("FILTER QUERIS5: ", this.filterConfig.fields[index].queries);
          
          break;
        case "codigo":
            //console.log("FILTER QUERIS4: ", this.filterConfig.fields[index].queries);
            let data2= this.codigoPolDayQueries.filter((item: any) => {
              if (item.value) {
                return (item.value.toLowerCase().indexOf(val.toLowerCase()) > -1);
              } else {
                return true;
              }
            })
            this.filterConfig.fields[index].queries = data2;
            //console.log("FILTER QUERIS5: ", this.filterConfig.fields[index].queries);
          
          break;
        case "area":
           //console.log("FILTER QUERIS4: ", this.filterConfig.fields[index].queries);
            let data3= this.areaPolDayQueries.filter((item: any) => {
              if (item.value) {
                return (item.value.toLowerCase().indexOf(val.toLowerCase()) > -1);
              } else {
                return true;
              }
            })
            this.filterConfig.fields[index].queries = data3;
            //console.log("FILTER QUERIS5: ", this.filterConfig.fields[index].queries);
          
          break;
          case "apartado":
            //console.log("FILTER QUERIS4: ", this.filterConfig.fields[index].queries);
             let data4= this.apartadoPolDayQueries.filter((item: any) => {
               if (item.value) {
                 return (item.value.toLowerCase().indexOf(val.toLowerCase()) > -1);
               } else {
                 return true;
               }
             })
             this.filterConfig.fields[index].queries = data4;
             //console.log("FILTER QUERIS5: ", this.filterConfig.fields[index].queries);
           break;
      
        default:
          break;
      }
    
  }

     // Reset filtered queries
  filterFieldSelected($event: FilterEvent): void {
    this.filterConfig.fields.forEach((field) => {

      switch (field.id) {
        case "nombre":
          field.queries = this.nombrePolDayQueries;
          break;
        case "codigo":
          field.queries = this.codigoPolDayQueries;
          break;
        case "area":
          field.queries = this.areaPolDayQueries;
          break;
        case "apartado":
          field.queries = this.apartadoPolDayQueries;
          break;
      
        default:
          break;
      }
     
    });
  }


}
